import React, { useEffect, useState, useRef } from "react";
// import Web3Modal from "web3modal";
// import { providers, Contract, BigNumber, utils } from "ethers";
import { Link, useNavigate } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { toast } from "react-toastify";
// import Rainbow from "@rainbow-me/rainbowkit";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import $ from "jquery";
import { useTheam } from "../../contextApi/TheamProvider";
// var reloads = false
const Header = ({ walletCheck, refresh, scrollToAbout, scrollToHome }) => {
  const navigate = useNavigate();
  // const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [reloads, setreloads] = useState(false);
  // const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider();
  const [reloads1, setreloads1] = useState(false);
  // const { myStates, setMyStates } = useMyContext();
  const { updateTheam, theam } = useTheam();

  useEffect(() => {
    if (reloads) {
      window.location.reload();
    }
  }, [reloads]);
  useEffect(() => {
    if (reloads1) {
      window.location.reload();
    }
  }, [reloads1]);

  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = "ac0b409a7d0aab537d213a6780db269b";

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  };

  const polygon = {
    chainId: 80001,
    name: "Polygon Mumbai",
    currency: "MATIC",
    explorerUrl: "https://mumbai.polygonscan.com",
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
  };
  const polygonMainnet = {
    chainId: 137,
    name: "Polygon",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com",
    rpcUrl: "https://polygon-rpc.com/",
  };

  const binanceSmartChain = {
    chainId: 97,
    name: "Binance Smart Chain Testnet",
    currency: "BNB",
    explorerUrl: "https://testnet.bscscan.com",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  };
  const binanceSmartMainnet = {
    chainId: 56,
    name: "Binance Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  };

  const metadata = {
    name: "BrainChain",
    description:
      "Discover the future of crypto projects with our cutting-edge BrainChain Launchpad. Get access to exclusive token sales and be a part of the revolution.",
    url: "https://www.brainchain.tech/",
    icons: ["https://www.brainchain.tech/img/logo.webp"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    defaultChain: [binanceSmartChain],
    chains: [
      mainnet,
      polygon,
      polygonMainnet,
      binanceSmartChain,
      binanceSmartMainnet,
    ],
    projectId,
  });

  useEffect(() => {
    const box = document.getElementById("box1");
    box.addEventListener("click", () => {
      if (box.classList.contains("toggle")) {
        box.classList.remove("toggle");
      } else {
        box.classList.add("toggle");
      }
    });
    $(document).ready(function () {
      // Handle checkbox click
      $(".nav-link1").click(function () {
        $(".body").toggleClass("mobile-menu");
      });

      // Handle nav-link click
      $("#box1").click(function () {
        $(".body").toggleClass("mobile-menu");
      });
    });
  }, []);
  const menubarclose = () => {
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };

  return (
    <div>
      {" "}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/img/logo.webp" alt="Brain Chain Logo" className="logo" />
            <span>BrainChain</span>
          </a>
          <div id="box1" className="box2">
            <span></span>
          </div>
          <button
            className="navbar-toggler ml-auto collapsed "
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            id="toggler1"
          >
            <span className="icon-bar">
              <i className="fa fa-times fa-2x"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <a className="nav-link active" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="/launchpad">
                  Launchpad
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/staking-farming">
                  Stake/Farm
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/claim">
                  Claims
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/profile">
                  Profile
                </a>
              </li>
              {/* <li className="nav-item">
              <a className="nav-link" href="/#about-us1">
                How to start
              </a>
            </li> */}
              <li onClick={scrollToHome} className="nav-item">
                <a className="nav-link " href="/#how-to-start">
                  How to start
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">
                  Contact Us
                </a>
              </li>
              <li className="nav-item drop dropdown">
                <Link className="nav-link " to="#" data-toggle="dropdown">
                  More <i className="fa fa-angle-double-down"></i>
                </Link>
                <div className="dropdown-menu">
                  <div className="manu_box">
                    <a className="mh1" href="/tier-system">
                      <i className="fa fa-star-o"></i> Tier System
                    </a>
                  </div>
                  <div className="manu_box">
                    <a className="mh1" href="/calendar">
                      <i className="fa fa-calendar-check-o"></i> IDOs Calendar
                    </a>
                  </div>

                  <div onClick={scrollToAbout} className="manu_box">
                    <a className="mh1" href="/#about-us">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      About Us
                    </a>
                  </div>
                  <div className="manu_box">
                    <Link
                      className="mh1"
                      to="#0"
                      data-toggle="modal"
                      data-target="#Community"
                      onClick={menubarclose}
                    >
                      <i className="fa fa-user-o"></i> Community
                    </Link>
                  </div>
                  <div className="manu_box">
                    <a className="mh1" href="/features">
                      <i class="fa fa-list-alt"></i> Features
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="connect_btn">
            <w3m-button balance="hide" />
          </div>
        </div>
      </nav>
      <div className="modal fade" id="Community">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="pr-4 pt-4">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body p-md-4 text-center ">
              <div className="nav_link mb-3">
                <h4> Follow Us On</h4>
                <div className="vertical-social  mt-4" data-wow-delay="0.1s">
                  <ul>
                    <li>
                      <a target="_blank" href="#">
                        {" "}
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="#">
                        <i className="fa fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="#">
                        {" "}
                        <img src="img/discord.svg" alt="discord" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="#">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="#">
                        <i className="fa fa-medium"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
