import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/HomePage/Home";
import Launchpad from "./components/LaunchPad/Launchpad";
import ProjectDetails from "./components/LaunchPad/ProjectDetails";
import StakingFarming from "./components/Staking-Farming/StakingFarming";
import Claim from "./components/Claim/Claim";
import Profile from "./components/Profile/Profile";
import TierSystem from "./components/TierSystem/TierSystem";
import ContactUs from "./components/ContactUs/ContactUs";
import Calendars from "./components/Calendar/Calendars";
import ApplyIdo from "./components/ApplyIdo/ApplyIdo";
import { SocketTest } from "./components/SocketTest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Features from "./components/Features/Features";
// import Header from "./components/Common/Header";
// import AddToHomeScreen from 'react-add-to-homescreen';
import { TheamProvider } from "./contextApi/TheamProvider";
const App = () => {
  // =========== home screen =============

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      // Correct service worker registration path
      navigator.serviceWorker
        .register("/service-worker.js")

        .then((registration) => {})
        .catch((error) => {});
    });
  }

  return (
    <>
      <TheamProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/launchpad" element={<Launchpad />} />
            {/* <Route path="/project-details/:id" element={<ProjectDetails />} /> */}
            <Route path="/project-details" element={<ProjectDetails />} />
            <Route path="/staking-farming" element={<StakingFarming />} />
            <Route path="/claim" element={<Claim />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/tier-system" element={<TierSystem />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/calendar" element={<Calendars />} />
            <Route path="/apply-ido" element={<ApplyIdo />} />
            <Route path="/features" element={<Features />} />
          </Routes>
        </Router>
        <ToastContainer
          limit={1}
          autoClose={2000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </TheamProvider>
    </>
  );
};

export default App;
