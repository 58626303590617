import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { getIdoDatas } from "../../services/Ido";
import { Link } from "react-router-dom";
import { baseUrls } from "../../Constant/BaseUrl";
import LaunchpadItem from "./LaunchpadItem";
import TabComponent from "./TabComponent";
import { useTheam } from "../../contextApi/TheamProvider";
const itemsPerPage = 9;
const tabs = [
  { id: "All", label: "All Projects" },
  { id: "Ongoing", label: "Ongoing Projects" },
  { id: "Upcoming", label: "Upcoming Projects" },
  { id: "INCUBATION", label: "INCUBATION Projects" },
  { id: "Past", label: "Past Projects" },
];
const Launchpad = () => {
  const [record, setRecord] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("All");
  const { totalAmts } = useTheam();

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setCurrentPage(1);
  };
  const getData = async () => {
    let data = { type: activeTab };
    const res = await getIdoDatas(data);

    if (res.status) {
      setRecord(res?.data);
    }
  };
  useEffect(() => {
    getData();
  }, [activeTab]);

  // ===============pagination====================

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = record.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const todayTimestamp = Math.floor(Date.now() / 1000);

  return (
    <div>
      <Header />

      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-3">Multi-Chain IDOs </h2>
              <p>
                Discover the future of crypto projects with our cutting-edge
                BrainChain Launchpad. Get access to exclusive token sales and be
                a part of the revolution.
              </p>
            </div>
          </div>

          <TabComponent
            handleTabChange={handleTabChange}
            activeTab={activeTab}
          />

          <div className="tab-content">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`tab-pane ${activeTab === tab.id ? "active" : ""}`}
                id={tab.id}
              >
                <div className="row">
                  {currentItems?.map((item, i) => {
                    return (
                      <LaunchpadItem
                        key={i}
                        item={item}
                        totalAmts={totalAmts}
                        todayTimestamp={todayTimestamp}
                        baseUrls={baseUrls}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>

          <div className="text-right mt-4">
            <ul className="pagination d-inline-flex">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
              {Array.from({
                length: Math.ceil(record.length / itemsPerPage),
              }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === Math.ceil(record.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer refresh={new Date().getTime()} />
    </div>
  );
};

export default Launchpad;
