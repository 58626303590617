// export const baseUrl = "http://192.168.1.20:5001/api/client";
// export const baseUrls = "http://192.168.1.20:5001";

export const baseUrl = "https://api.brainchain.tech/api/client";
export const baseUrls = "https://api.brainchain.tech";

// export const baseUrl = "https://brainchainapi.technoloaderitservices.com/api/client"
// export const baseUrls = "https://brainchainapi.technoloaderitservices.com";



