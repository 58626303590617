import React, {  } from "react";

const tabs = [
  { id: "All", label: "All" },
  { id: "Ongoing", label: "Ongoing" },
  { id: "Upcoming", label: "Upcoming" },
  { id: "INCUBATION", label: "INCUBATION", smallLabel: "s" },
  { id: "Past", label: "PAST IDO", smallLabel: "s" },
];

function TabComponent({ handleTabChange, activeTab }) {
  return (
    <ul className="nav launchpad_tab mt-md-4 text-center mb-md-5 mb-3">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab.id}>
          <a
            className={activeTab === tab.id ? "active" : ""}
            data-toggle="tab"
            href={`#${tab.id}`}
            onClick={() => handleTabChange(tab.id)}
          >
            {tab.label}
            {tab.smallLabel && <small>{tab.smallLabel}</small>}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default TabComponent;
