import React from "react";
import { Modal, Form, Table, Button } from "react-bootstrap";

const CalculatorModal = ({
  shows,
  handleClose,
  calculatorAmount,
  handleChangeForCalculator,
  validNumber,
}) => {
  const calculateRowValue = (amount, rate, days) => {
    return (amount * rate * days) / 365;
  };

  const rows = [
    { days: 30, rate: 0.01, label: "1% APR" },
    { days: 60, rate: 0.03, label: "3% APR" },
    { days: 90, rate: 0.06, label: "6% APR" },
    { days: 120, rate: 0.1, label: "10% APR" },
    { days: 180, rate: 0.2, label: "20% APR" },
    { days: 365, rate: 0.5, label: "50% APR" },
  ];

  return (
    <Modal show={shows} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ROI Calculator</Modal.Title>
      </Modal.Header>
      <Modal.Body className="calculator_popop">
        <Form.Control
          type="tel"
          placeholder="Enter Amount"
          value={calculatorAmount}
          onChange={handleChangeForCalculator}
          onKeyPress={validNumber}
        />

        <Table className="mt-3">
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.days} Day</td>
                <td>{row.label}</td>
                <td>
                  {calculatorAmount
                    ? calculateRowValue(
                        calculatorAmount,
                        row.rate,
                        row.days
                      ).toFixed(4)
                    : "0"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="btn btn_man">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculatorModal;
