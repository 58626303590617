import React, { createContext, useContext, useState, useEffect } from "react";

const TheamContext = createContext();

export const TheamProvider = ({ children }) => {
  const [theam, setTheam] = useState(localStorage.getItem("address"));
  const [totalAmts, setTotalAmts] = useState(0);


  useEffect(() => {
    localStorage.setItem("address", theam);
  }, []);

  const updateTheam = (newtheam) => {
    localStorage.setItem("address", newtheam);
    setTheam(newtheam);
  };
  const updateAmt = (newAmt) => {
  
    setTotalAmts(newAmt);
  };

  return (
    <TheamContext.Provider value={{ updateTheam, theam, updateAmt, totalAmts }}>
      {children}
    </TheamContext.Provider>
  );
};

export const useTheam = () => {
  return useContext(TheamContext);
};
