import * as opsService from "./Ops";

import { contactAdd } from "../Constant/Api";
import { baseUrl } from "../Constant/BaseUrl";

const contactUsAdd = async (data, token) => {
  let result = await opsService.postdata(contactAdd, data, token);

  return result;
};
// const getAdminData = async (data, token) => {
//   let result = await opsService.getData(getAdmin, data, token);

//   return result;
// };
// const actionActivities = async (data, token) => {
//   let result = await opsService.postdata(actionHandle, data, token);

//   return result;
// };
// const getContactUsData = async (data, token) => {
//   let result = await opsService.getData(getContactUs, data, token);

//   return result;
// };

// const adminDataById = async (data, token) => {
//   let result = await opsService.postdata(adminDataByIds, data, token);

//   return result;
// };






export { contactUsAdd };
