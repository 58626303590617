import React, { useEffect, useState, useRef } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
import ProgressBar from "react-bootstrap/ProgressBar";
// import { Modal, Button } from "react-bootstrap";
import { Modal, Button, Table } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTheam } from "../../contextApi/TheamProvider";
import { getIdoAllocation, idoAllocationdataById } from "../../services/Ido";
import $ from "jquery";
import StakingInfoBox from "./partials/StakingInfoBox";
import { Tier } from "./partials/Tier";
import Spinner from "react-bootstrap/Spinner";
import Big from "big.js";
import {
  tokenabi,
  tokenaddress,
  stakeabi,
  stakeaddress,
  cakeLp,
  cakeAbi,
  launchpadFarmingAddress,
  launchpadFarmingAbi,
} from "../constant";
import {
  stackingApply,
  checkMultipleRequest,
  getStackingAddressData,
} from "../../services/Stacking";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import CalculatorModal from "./partials/CalculatorModal";

const StakingFarming = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [pageRefreshed, setPageRefreshed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { updateTheam, theam } = useTheam();
  const [clickedAction, setClickedAction] = useState(null);
  const [action, setAction] = useState("Buy");
  const [amountErr, setAmountErr] = useState("");
  const [walletConnected, setwalletConnected] = useState(false);
  const [stakeamount, setstakeamount] = useState(0);
  const [stakeamount1, setstakeamount1] = useState(0);
  const [stakeamount2, setstakeamount2] = useState(0);
  const [stakeamount3, setstakeamount3] = useState(0);
  const [stakeamount4, setstakeamount4] = useState(0);
  const [stakeamount5, setstakeamount5] = useState(0);
  const [stakeamount6, setstakeamount6] = useState(0);
  const [stakeamount7, setstakeamount7] = useState(0);
  const [stakeamount8, setstakeamount8] = useState(0);
  const [stakeamount9, setstakeamount9] = useState(0);
  const [cblock, setCblock] = useState(0);
  const [Eblock, setEblock] = useState(0);

  const [spinner, setSpinner] = useState(false);
  const [days, setDays] = useState();
  const [daysLeft, setDaysLeft] = useState(0);
  const [aprr, setAprr] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [tokenbalance, settokenbalance] = useState();
  const [tokenbalancelp, settokenbalancelp] = useState();
  const [lockDurations, setlLockDurations] = useState();
  const [participants, setlParticipants] = useState();
  const [widthPercentages, setWidthPercentage] = useState(0);
  const [totalReward, setlTotalReward] = useState();
  const [rewardBalances, setRewardBalances] = useState();
  const [rewardAmt, setRewardAmt] = useState(0);
  const [allowence, setallowence] = useState(0);

  const [index, setindexs] = useState(1);
  const [inputamount1, setinputamount1] = useState();
  const [inputamount, setinputamount] = useState();
  const [show, setshow] = useState(false);
  const [walletTotalAmount, setWalletTotalAmount] = useState(0);
  const [totalStakeAmount, setTotalStakeAmount] = useState(0);
  const [calculatorAmount, setcalculatorAmount] = useState();
  // const [address, setAddress] = useState("");
  // const [reloads, setReloads] = useState(false);

  const [babtHolders, setBabtHolders] = useState({
    tier1: "",
    tier2: "",
    tier3: "",
    tier4: "",
    tier5: "",
    tier6: "",
  });
  const initialValue = {
    user_per_tier6: "",
    user_per_tier5: "",
    user_per_tier4: "",
    user_per_tier3: "",
    user_per_tier2: "",
    user_per_tier1: "",
  };
  const [formValue, setFormValue] = useState(initialValue);

  const MaxWalletAmt = () => {
    const num1 = new Big(stakeamount);
    const num2 = new Big(rewardAmt);
    let totalAmount = num1.plus(num2).toString();

    totalAmount = totalAmount.slice(0, totalAmount.lastIndexOf(".") + 6);

    if (totalAmount) {
      setinputamount1(totalAmount);
    } else {
      console.error(
        "Invalid totalAmount. Please check stakeamount and rewardAmt."
      );
    }
  };
  const [shows, setShows] = useState(false);

  const handleClose = () => {
    setShows(false);
    setcalculatorAmount();
  };
  const handleShows = () => setShows(true);
  const handleChangeForCalculator = (e) => {
    setcalculatorAmount(e.target.value);
  };

  const ModelRef = useRef(null);

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      toast.error(error);
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };

  const getTokenDb = async () => {
    const signer = await getSignerOrProvider(true);
    const address = await signer.getAddress();

    if (address) {
      const { data } = await getStackingAddressData({ address });
      if (data) {
        setWalletTotalAmount(data[0].total_amount);
      }
    }
  };

  function redirect() {
    setTimeout(function () {
      window.location.replace("/staking-farming");
    }, 1500);
  }
  useEffect(() => {
    checkapproval();
  }, [inputamount]);
  
  const checkapproval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.allowance(address, stakeaddress);
      // console.log(formatUnits(balance,18),"bala")
      console.log(parseFloat(inputamount), "balancein");
      // console.log(parseFloat(inputamount), "balance");
      const allowence = formatUnits(balance, 18);
      setallowence(allowence);
      console.log(parseFloat(allowence), "balanceall");

      if (parseFloat(allowence) >= parseFloat(inputamount)) {
        setshow(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const MaxAmount = (amt) => {
    // checkapproval();
    setinputamount(amt);

    if (parseFloat(allowence) <= parseFloat(amt)) {
      setshow(false);
    }
  };

  const withdrawstake = async (type, trans_type) => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();

      if (address == address) {
        setButtonDisable(true);

        let datas = {
          wallet_address: address,
          type,
        };
        let resp = await checkMultipleRequest(datas);
        if (!resp.status) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }
        const contract = new Contract(stakeaddress, stakeabi, signer);
        const tx = await contract.withdraw(index);
        setSpinner(true);
        const transactionHash = tx.hash;

        const data = {
          address,
          inputamount: inputamount1,
          trans_id: transactionHash,
          days,
          type,
          trans_type,
        };

        const res = await stackingApply(data);

        if (res.status) {
          toast.dismiss();
          toast.success(res.message);
          await tx.wait();
          setshow(true);
          setSpinner(false);
          setButtonDisable(false);
          redirect();
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
      // if (userAddress == null) {
      //   toast.dismiss();
      //   toast.error("Please connect with metamask");
      //   return;
      // }
    } catch (err) {
      console.log(err.message, "error is ");
      if (
        err.message.includes(
          `execution reverted: "Your withdraw time is not reached yet!"`
        )
      ) {
        toast.error("Your withdraw time is not reached yet!");
      }
      if (
        err.message.includes(
          `execution reverted: "You don't have enough stake to withdraw"`
        )
      ) {
        toast.error("You don't have enough stake to withdraw");
      }
      if (err.message.includes("ERC20: transfer amount exceeds balance")) {
        toast.error("Amount Exceeds wallet balance");
      }
      if (
        err.message.includes(
          `execution reverted: "No available stakes to withdraw"`
        )
      ) {
        toast.error("No available stakes to withdraw");
      }
      toast.error(err);

      console.error(err);
    } finally {
      setButtonDisable(false);
    }
  };
  const approve = async () => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }
      const signer = await getSignerOrProvider(true);

      const provider = await getSignerOrProvider();

      if (isConnected) {
        // if (userAddress == null) {
        //   toast.dismiss();
        //   toast.error("Please connect with metamask");
        //   return;
        // }
        setButtonDisable(true);

        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }
        if (!inputamount) {
          toast.dismiss();
          toast.error(" Please enter amount");
          return;
        }
        const contract = new Contract(tokenaddress, tokenabi, signer);

        const tx = await contract.approve(
          stakeaddress,
          parseEther(inputamount)
        );
        setSpinner(true);

        await tx.wait();
        toast.success("Approval successful");
        setshow(true);
        setSpinner(false);
        setButtonDisable(false);
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }

      // if (connectWallet == false) {
      //   toast.dismiss();
      //   toast.error("Please connect with metamask");
      //   return;
      // }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      console.error(err);
    }
  };
  const approve5 = async () => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }

      const signer = await getSignerOrProvider(true);

      const provider = await getSignerOrProvider();

      if (isConnected) {
        // if (userAddress == null) {
        //   toast.dismiss();
        //   toast.error("Please connect with metamask");
        //   return;
        // }
        setButtonDisable(true);
        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }
        if (!inputamount) {
          toast.dismiss();
          toast.error(" Please enter amount");
          return;
        }
        const contract = new Contract(cakeLp, cakeAbi, signer);

        const tx = await contract.approve(
          launchpadFarmingAddress,
          parseEther(inputamount)
        );
        setSpinner(true);

        await tx.wait();
        toast.success("Approval successful");
        setshow(true);
        setSpinner(false);
        setButtonDisable(false);
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }

      // if (connectWallet == false) {
      //   toast.dismiss();
      //   toast.error("Please connect with metamask");
      //   return;
      // }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      console.error(err);
    }
  };
  const handleChange1 = async (e) => {
    setinputamount1(e.target.value);
  };

  const handleChange = async (e) => {
    setinputamount(e.target.value);
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.allowance(address, stakeaddress);
      const balanceBN = formatUnits(balance, 18);
      console.log(typeof e.target.value, "aval");
      console.log(balanceBN, "aval");

      if (parseFloat(balanceBN) >= parseFloat(e.target.value)) {
        setshow(true);
        console.log("this is here");
      } else {
        setshow(false);
        console.log("this is here not");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleChange5 = async (e) => {
    setinputamount(e.target.value);
    try {
      if (address == address) {
        const signer = await getSignerOrProvider(true);
        const provider = await getSignerOrProvider();
        const address = await signer.getAddress();
        const contract = new Contract(cakeLp, cakeAbi, provider);
        const balance = await contract.allowance(
          address,
          launchpadFarmingAddress
        );
        const balanceBN = formatUnits(balance, 18);

        if (parseFloat(balanceBN) >= parseFloat(e.target.value)) {
          setshow(true);
        } else {
          setshow(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const depositamount = async (type, trans_type) => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }
      await checkapproval();
      setButtonDisable(true);
    
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (address == address) {
        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }
        let datas = {
          wallet_address: address,
          type,
        };
        let resp = await checkMultipleRequest(datas);
        if (!resp.status) {
          toast.dismiss();
          toast.error(resp.message);
          return;
        }

        const contract = new Contract(stakeaddress, stakeabi, signer);
        const tx = await contract.stake(parseEther(inputamount), index);
        const transactionHash = tx.hash;

        const data = {
          address,
          inputamount,
          trans_id: transactionHash,
          days,
          type,
          trans_type,
        };
        setSpinner(true);
        const res = await stackingApply(data);

        if (res.status) {
          setButtonDisable(false);

          toast.dismiss();
          toast.success(res.message);
          setSpinner(true);
          await tx.wait();
          redirect();
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      if (
        err.message.includes(
          `execution reverted: "You don't have enough BABT token"`
        )
      ) {
        toast.error("You don't have enough BABT token to verify our Kyc !");
      }
      if (err.message.includes(`execution reverted:  "You are blocked"`)) {
        toast.error("You are blocked");
      }
      if (
        err.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toast.error("Insufficient allowance");
      }

      if (
        err.message.includes(
          "execution reverted: ERC20: transfer amount exceeds balance"
        )
      ) {
        toast.error("You don't have enough balance to stake !");
      }
    }
  };
  const farming = async (type, trans_type) => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }

      setButtonDisable(true);
      await checkapproval();

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (address == address) {
        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }
        if (!inputamount) {
          toast.dismiss();
          toast.error(" Please enter amount");
          return;
        }
        const contract = new Contract(
          launchpadFarmingAddress,
          launchpadFarmingAbi,
          signer
        );
        let amttt = inputamount;
        if (typeof inputamount == "number") {
          amttt = inputamount.toString();
        }
        const tx = await contract.stake(parseEther(amttt));
        if (tx) {
          setButtonDisable(false);

          setSpinner(true);
          await tx.wait();
          toast.dismiss();
          toast.success("Transaction Success");
          redirect();
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);

      if (err.message.includes(`execution reverted: "Invalid period"`)) {
        toast.error("Invalid period");
      }
      if (
        err.message.includes(
          `execution reverted: "Make sure to add enough allowance"`
        )
      ) {
        toast.error("Make sure to add enough allowance");
      }
      if (err.message.includes(`execution reverted:  "You are blocked"`)) {
        toast.error("You are blocked");
      }

      if (
        err.message.includes(
          `execution reverted: "ERC20: transfer amount exceeds balance"`
        )
      ) {
        toast.error("You don't have enough balance to stake !");
      }
    }
  };
  const farmingWithdraw = async () => {
    try {
      if (!isConnected) {
        toast.error("User Disconnected");
      }

      setButtonDisable(true);

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();

      if (isConnected) {
        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }
        if (!inputamount) {
          toast.dismiss();
          toast.error(" Please enter amount");
          return;
        }

        const contract = new Contract(
          launchpadFarmingAddress,
          launchpadFarmingAbi,
          signer
        );
        let amttt = inputamount;
        if (typeof inputamount == "number") {
          amttt = inputamount.toString();
        }
        console.log(amttt, "amttt", typeof amttt);
        const tx = await contract.withdraw(parseEther(amttt));
        if (tx) {
          setSpinner(true);
          setButtonDisable(true);
          await tx.wait();
          toast.dismiss();
          toast.success("Withdraw Success");
          setSpinner(false);
          setButtonDisable(false);
          redirect();
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      console.log("message");
      setSpinner(false);
      setButtonDisable(false);
      if (err.message.includes(`execution reverted: "No rewards generated"`)) {
        toast.error("No rewards generated");
      }
      if (
        err.message.includes(
          `execution reverted: "Can't withdraw before lock duration"`
        )
      ) {
        toast.error("Can't withdraw before lock duration");
      }
      if (
        err.message.includes(`execution reverted: "No stakes found for user"`)
      ) {
        toast.error(" No stakings found, please stake");
      }

      if (err.message.includes(`execution reverted:  "You are blocked"`)) {
        toast.error("You are blocked");
      }
      if (
        err.message.includes(`execution reverted: "No stakes found for user"`)
      ) {
        toast.error("No stakes found for user");
      }
    }
  };
  const harvest = async (type, trans_type) => {
    try {
      setButtonDisable(true);

      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();

      if (address == address) {
        if (chainId != 97) {
          toast.dismiss();
          toast.error("Please select binance testnet chain");
          return;
        }

        const contract = new Contract(
          launchpadFarmingAddress,
          launchpadFarmingAbi,
          signer
        );
        const tx = await contract.claimRewards();
        if (tx) {
          setSpinner(true);
          setButtonDisable(true);
          await tx.wait();
          toast.dismiss();
          toast.success("Harvest Success");
          setSpinner(false);
          setButtonDisable(false);
          redirect();
        }
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);

      if (err.message.includes(`execution reverted: "No rewards generated"`)) {
        toast.error("No rewards generated");
      }
      if (
        err.message.includes(
          `execution reverted: "You don't have enough BABT token"`
        )
      ) {
        toast.error("You don't have enough BABT token to verify our Kyc !");
      }
      if (err.message.includes(`execution reverted:  "You are blocked"`)) {
        toast.error("You are blocked");
      }

      if (
        err.message.includes(`execution reverted: "No stakes found for user"`)
      ) {
        toast.error("No stakes found for user");
      }
    }
  };

  const getstakeamount = async (e) => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(stakeaddress, stakeabi, provider);
      const balance = await contract.stakesamount(address, e);
      console.log(balance.lockTime, "hhh");
      setstakeamount(formatUnits(balance[0], 18));
      const contract1 = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );

      const targetTimestamp12 = balance.lockTime;
      const toStr = targetTimestamp12.toString();
      const targetTimestamp = Number(toStr);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const timeDifferenceInSeconds = targetTimestamp - currentTimestamp;
      const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

      console.log(targetTimestamp, "hhh123");
      setDaysLeft(timeDifferenceInMinutes);

      const balance1 = await contract1.userDeposits(address);
      setstakeamount1(formatUnits(balance1[0], 18));
      const balance2 = await contract1.accShare();

      setstakeamount2(formatUnits(balance2, 6));
      const balance3 = await contract1.rewardBalance();

      setstakeamount3(formatUnits(balance3, 18));
      const balance4 = await contract1.totalReward();
      setstakeamount4(formatUnits(balance4, 18));
      const balance5 = await contract1.rewPerBlock();
      setstakeamount5(formatUnits(balance5, 18));
      const balance6 = await contract1.calculate(address);
      setstakeamount6(formatUnits(balance6, 18));
      const balance8 = await contract1.userDeposits(address);
      setstakeamount8(formatUnits(balance8[0], 18));
      setstakeamount9(balance8[1]);
      const cBlocks = await contract1.currentBlock();
      setCblock(cBlocks);
      const days = await contract1.lockDuration();
      const toStr1 = days.toString();
      const targetTimestamp1 = Number(toStr1);
      const toStr2 = balance8[1].toString();
      const targetTimestamp2 = Number(toStr2);
      const toStr3 = cBlocks.toString();
      const targetTimestamp3 = Number(toStr3);

      setlLockDurations(targetTimestamp1);
      console.log("LOK", cBlocks);

      const timing = targetTimestamp2 + targetTimestamp1 * 1200;
      console.log("timing", timing);
      const nowblock = timing - targetTimestamp3;
      console.log("nowblock", nowblock);
      if (nowblock > 0) {
        const newTiming = nowblock / 28800;
        setEblock(newTiming.toFixed(0));
      } else {
        setEblock(0);
      }

      const balance7 = await contract1.stakedBalance();
      setstakeamount7(formatUnits(balance7, 18));

      const Totalbalance = await contract.totalStakedAmount(address);

      setTotalStakeAmount(formatUnits(Totalbalance, 18));
    } catch (err) {
      console.error(err);
    }
  };

  const setindex = async (e, apr) => {
    setindexs(e);
    setAprr(apr);
    if (address == address) {
      getstakeamount(e);
      gettokenamount(e);
    }

    const durationMapping = { 1: 30, 2: 60, 3: 90, 4: 120, 5: 180, 6: 365 };
    setDays(durationMapping[e]);
  };
  const getBlanceTokenAmanDevToken = async () => {
    try {
      const provider = await getSignerOrProvider();
      const signer = await getSignerOrProvider(true);
      const address = await signer.getAddress();
    } catch (err) {
      console.error(err);
    }
  };
  const gettokenamount = async (e) => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.balanceOf(address);
      const originalValues = formatUnits(balance, 18);
      const roundedValues = parseFloat(originalValues).toFixed(4);
      const trimmedValues = parseFloat(roundedValues).toString();
      // let walletBal = formatUnits(balance, 18);

      // walletBal = Number(walletBal);
      settokenbalance(trimmedValues);
      const contracts = new Contract(stakeaddress, stakeabi, provider);
      const balances = await contracts.reward(address, e);
      const originalValue = formatUnits(balances.amount, 18);
      const roundedValue = parseFloat(originalValue).toFixed(8);

      const trimmedValue = parseFloat(roundedValue).toString();
      setRewardAmt(trimmedValue);
    } catch (err) {
      console.error(err);
    }
  };

  const getTokenCakeAmount = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();

      const contract = new Contract(cakeLp, cakeAbi, provider);
      const balance = await contract.balanceOf(address);

      settokenbalancelp(formatUnits(balance, 18));
    } catch (err) {
      console.error(err);
    }
  };
  const getunlockDays = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );
      const days = await contract.lockDuration();

      setlLockDurations(days);
    } catch (err) {
      console.error(err);
    }
  };
  const getTotalReward = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );
      const reward = await contract.totalReward();

      setlTotalReward(formatUnits(reward, 18));
    } catch (err) {
      console.error(err);
    }
  };
  const getRewardBalence = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );
      const reward = await contract.rewardBalance();

      setRewardBalances(formatUnits(reward, 18));
    } catch (err) {
      console.error(err);
    }
  };

  const getTotalParticipants = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );
      const participants = await contract.totalParticipants();

      setlParticipants(parseInt(participants));
    } catch (err) {
      console.error(err);
    }
  };

  const connectWallet = async () => {
    try {
      setwalletConnected(true);
      if (index == 1) {
        setDays(30);
      }
      await gettokenamount();
      await getstakeamount();
      await getTokenCakeAmount();
      await getunlockDays();
      await getTotalReward();
      await getTotalParticipants();
      await getRewardBalence();
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    if (isConnected === false && !pageRefreshed) {
      // Refresh the page only if isConnected goes from true to false
      setPageRefreshed(true);
    }
  }, [isConnected, pageRefreshed]);

  React.useEffect(() => {
    if (isConnected) {
      connectWallet();
      gettokenamount();
      getTokenDb();
      getBlanceTokenAmanDevToken();
      console.log("yes");
    } else {
      console.log("yes");
    }
  }, [isConnected, theam]);

  useEffect(() => {
    if (isConnected) {
      getstakeamount(index);
      gettokenamount(index);
      if (walletConnected) {
        setwalletConnected(true);
      } else {
        setwalletConnected(false);
      }
    }
  }, [walletConnected, theam]);
  useEffect(() => {
    // Update widthPercentage whenever stakeamount3 or stakeamount4 changes
    const newWidthPercentage = 100 - (stakeamount3 * 100) / stakeamount4;
    setWidthPercentage(!isNaN(newWidthPercentage) ? newWidthPercentage : 0);
  }, [stakeamount3, stakeamount4]);
  const widthPercentage = 100 - (stakeamount3 * 100) / stakeamount4;

  const handleShow = (type) => {
    if (isConnected) {
      setShowModal(true);
      setAction(type);
    } else {
      toast.error("User Disconnected");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setinputamount("");
    setAmountErr("");
  };

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const config = localStorage.getItem("jwtToken");
      const res = await getIdoAllocation(config);

      if (res?.status) {
        // toast.success(res.message);

        const babtHoldersData = res?.data || {};

        setBabtHolders({
          tier1: babtHoldersData.babtHoldersTier1?.babtHolders || "",
          tier2: babtHoldersData.babtHoldersTier2?.babtHolders,
          tier3: babtHoldersData.babtHoldersTier3?.babtHolders || "",
          tier4: babtHoldersData.babtHoldersTier4?.babtHolders || "",
          tier5: babtHoldersData.babtHoldersTier5?.babtHolders || "",
          tier6: babtHoldersData.babtHoldersTier6?.babtHolders || "",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = localStorage.getItem("jwtToken");
        const res = await idoAllocationdataById(config);

        if (res.status && res.data.length > 0) {
          const value = res.data[0];
          const apiData = {
            user_per_tier1: value?.user_per_tier1,
            user_per_tier2: value?.user_per_tier2,
            user_per_tier3: value?.user_per_tier3,
            user_per_tier4: value?.user_per_tier4,
            user_per_tier5: value?.user_per_tier5,
            user_per_tier6: value?.user_per_tier6,
          };

          const updatedFormValue = { ...formValue };

          for (const key in apiData) {
            if (key in updatedFormValue) {
              updatedFormValue[key] = apiData[key];
            }
          }

          setFormValue(updatedFormValue);
        } else {
          console.error("API call failed:", res.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
    getData();
  }, []);

  useEffect(() => {
    $("#WEB3_CONNECT_MODAL_ID").empty();
    $("body").removeAttr("style");
  }, []);
  const tiers = [
    { name: "TIER 1", requirement: 600 },
    { name: "TIER 2", requirement: 1500 },
    { name: "TIER 3", requirement: 3500 },
    { name: "TIER 4", requirement: 7500 },
    { name: "TIER 5", requirement: 15000 },
    { name: "TIER 6", requirement: 35000 },
  ];
  const tabData = [
    { index: 1, label: "30 Days", tab: "one", apr: 1 },
    { index: 2, label: "60 Days", tab: "two", apr: 3 },
    { index: 3, label: "90 Days", tab: "three", apr: 6 },
    { index: 4, label: "120 Days", tab: "four", apr: 10 },
    { index: 5, label: "180 Days", tab: "five", apr: 20 },
    { index: 6, label: "365 Days", tab: "six", apr: 50 },
  ];
  const maxAmt = (amt) => {
    setinputamount(amt);
  };

  return (
    <div>
      <Header />

      <section className="in_page staking-area">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>

        <div className="container">
          <div className="row mt-md-4">
            <div className="col-12 col-md-6 fw_box mb-4">
              <h2 className=" hadding mb-2 mt-2 mr-md-4 mb-4 text-center text-md-left">
                {" "}
                <span className="color_g">Stake</span> or{" "}
                <span className="color_g">Farm</span> $BRAIN and get access to{" "}
                <span className="color_g">Exclusive</span> Token sales
              </h2>
              <div className="box_mansf mt-auto">
                <div className="row mb-3">
                  {tiers.map((tier, index) => (
                    <Tier
                      key={index}
                      name={tier.name}
                      requirement={tier.requirement}
                      filled={
                        babtHolders[`tier${index + 1}`] >=
                        formValue[`user_per_tier${index + 1}`]
                      }
                    />
                  ))}
                </div>
                <div className="pb-2">
                  Doesn't matter if a Tier is Open or Filled, you can put any
                  amount of tokens on Staking or Farming and earn passive
                  income.
                </div>
                <div>
                  BABT is only required if you want to participate in IDOs.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="no-hover staking-card single-staking launchpad_box">
                <ul className="nav nav_tab2 nav_tab2_right  ml-auto">
                  <li className="nav-item">
                    <a className=" active" data-toggle="tab" href="#STAKE_tab">
                      {" "}
                      STAKE{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="" data-toggle="tab" href="#FARM_tab">
                      {" "}
                      FARM{" "}
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active " id="STAKE_tab">
                    <h3 className="mb-4">Staking Panel </h3>
                    <h5 className="m-0">Lock Periods</h5>
                    <ul
                      className="nav nav-tabs staking-tabs border-0 mb-4 mt-3"
                      id="myTab"
                      role="tablist"
                    >
                      {tabData.map(({ index, label, tab, apr }) => {
                        return (
                          <li
                            key={index}
                            className="nav-item"
                            role="presentation"
                          >
                            <a
                              onClick={() => setindex(index, apr)}
                              className={`tab-link${
                                index === 1 ? " active" : ""
                              }`}
                              id={`tab-${tab}-tab`}
                              data-toggle="tab"
                              href={`#tab-${tab}`}
                              role="tab"
                              aria-controls={`tab-${tab}`}
                              aria-selected={index === 1 ? "true" : "false"}
                            >
                              {label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="tab-content mt-3" id="myTabContent">
                      {tabData.map((tab, index) => (
                        <div
                          key={index}
                          className={`tab-pane fade ${
                            index === 0 ? "active show" : ""
                          }`}
                          id={`tab-${index + 1}`}
                          role="tabpanel"
                          aria-labelledby={`tab-${index + 1}-tab`}
                        >
                          <StakingInfoBox
                            stakeamount={stakeamount}
                            rewardAmt={rewardAmt}
                            tokenbalance={tokenbalance}
                            daysLeft={daysLeft}
                            apr={aprr}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="input-box my-4">
                      <div className="input-area d-flex flex-column flex-md-row mb-3">
                        <div className="input-text">
                          <input
                            onChange={handleChange}
                            value={inputamount}
                            type="tel"
                            onKeyPress={validNumber}
                            placeholder="Enter BRAIN Amount"
                          />
                          <button
                            onClick={() => MaxAmount(tokenbalance)}
                            style={{ zIndex: 10000 }}
                          >
                            Max
                          </button>
                        </div>

                        {!show ? (
                          <button
                            onClick={() => {
                              setClickedAction("approve");
                              approve();
                            }}
                            className={`btn btn_man mt-2 mt-md-0 ml-md-3 ${
                              spinner && clickedAction === "approve"
                                ? "btn_loader11"
                                : ""
                            }`}
                            disabled={
                              buttonDisable ||
                              (spinner && clickedAction !== "approve")
                            }
                          >
                            <span className="l_hide_text">Approve</span>
                            {spinner && clickedAction == "approve" && (
                              <span className="btn_loader"></span>
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setClickedAction("deposit");
                              depositamount("deposit", "stacking");
                            }}
                            className={`btn btn_man mt-2 mt-md-0 ml-md-3 ${
                              spinner && clickedAction === "deposit"
                                ? "btn_loader11"
                                : ""
                            }`}
                            disabled={
                              buttonDisable ||
                              (spinner && clickedAction !== "deposit")
                            }
                          >
                            <span className="l_hide_text">Deposit</span>
                            {spinner && clickedAction === "deposit" && (
                              <span className="btn_loader"></span>
                            )}
                          </button>
                        )}
                      </div>

                      <div className="input-area d-flex flex-column flex-md-row">
                        <div className="input-text">
                          <input
                            onChange={handleChange1}
                            type="tel"
                            onKeyPress={validNumber}
                            value={inputamount1}
                            disabled={true}
                            placeholder="Enter BRAIN Amount"
                          />
                          <button
                            onClick={MaxWalletAmt}
                            style={{ zIndex: "10000" }}
                          >
                            Max
                          </button>
                        </div>

                        <button
                          onClick={() => {
                            setClickedAction("withdraw");
                            withdrawstake("withdrawal", "stacking");
                          }}
                          className={`btn btn_man mt-2 mt-md-0 ml-md-3 ${
                            spinner && clickedAction === "withdraw"
                              ? "btn_loader11"
                              : ""
                          }`}
                          disabled={
                            buttonDisable ||
                            (spinner && clickedAction !== "withdraw")
                          }
                        >
                          <span className="l_hide_text">Withdraw</span>
                          {spinner && clickedAction === "withdraw" && (
                            <span className="btn_loader"></span>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      ROI Calculator :{" "}
                      <i
                        className="fa fa-calculator ml-2 calculator_icon"
                        onClick={handleShows}
                      ></i>
                    </div>
                    <span>
                      Upon withdrawal, your rewards will be automatically sent
                      to your wallet.
                    </span>
                  </div>

                  <CalculatorModal
                    shows={shows}
                    handleChangeForCalculator={handleChangeForCalculator}
                    handleClose={handleClose}
                    calculatorAmount={calculatorAmount}
                    validNumber={validNumber}
                  />
                  <div className="tab-pane " id="FARM_tab">
                    <h3 className="mb-4">Farming Panel </h3>
                    <div>
                      <div className="row pool_details_font">
                        <h5 className="col-6">Pool Details</h5>
                        <div className="col-6 text-right">
                          <p className="h5">
                            <span>{stakeamount6} BRAIN</span> Earned
                          </p>
                          <div className="input-box">
                            <button
                              // disabled={buttonDisable}
                              className={`btn btn_man bh50 mt-2 mt-md-0 ml-md-3 ${
                                spinner ? "btn_loader11" : ""
                              }`}
                              disabled={buttonDisable || spinner}
                              // className="btn btn_man mt-1"
                              onClick={harvest}
                            >
                              <span className="l_hide_text">Harvest</span>
                              {spinner && <span className="btn_loader"></span>}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <div className="lr_man">
                          <label className="left_label">APR</label>
                          <p className="right_label">
                            {" "}
                            {lockDurations ? "4%" : "--"}
                          </p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">
                            REWARD PER MINUTE
                          </label>
                          <p className="right_label">
                            {stakeamount5 * 20} BRAIN
                          </p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">UNLOCKS IN</label>
                          <p className="right_label">{Eblock} Days</p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">YOUR STAKE</label>
                          <p className="right_label">{stakeamount1} Cake-LP</p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">POOL SHARE</label>
                          <p className="right_label">
                            {!isNaN((stakeamount8 * 100) / stakeamount7)
                              ? ((stakeamount8 * 100) / stakeamount7).toFixed(2)
                              : "--"}
                            %
                          </p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">PARTICIPANTS</label>
                          <p className="right_label">
                            {!isNaN(participants) ? participants : "--"}
                          </p>
                        </div>
                        <div className="lr_man">
                          <label className="left_label">
                            DISTRIBUTED TOKENS
                          </label>

                          {console.log(
                            stakeamount3 + "/" + stakeamount4,
                            "stakeamount3 + " / " + stakeamount4"
                          )}
                          <p className="right_label">
                            {{ stakeamount4 } / { totalReward }
                              ? { stakeamount3 } / { totalReward }
                              : stakeamount3 + "/" + stakeamount4}
                          </p>
                        </div>
                      </div>

                      <div className="item-progress mt-2">
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${widthPercentages}%` }}
                            aria-valuenow={widthPercentages}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {`${widthPercentages.toFixed(2)}%`}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 mt-2 fbs">
                        <span className="">Balance : </span>
                        <span>
                          <span className="mr-1">{tokenbalancelp}</span>
                          Cake-LP
                        </span>
                      </div>
                      <Link
                        to={
                          "https://pancakeswap.finance/v2/add/0x090B8E829915711aD7F63ddD37F3529337EF4aD9/0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd?chain=bscTestnet"
                        }
                        className="btn w100 btn_man"
                        target="_blank"
                      >
                        View Pool
                      </Link>
                      <div className="mt-4 row">
                        <div className="col-6">
                          {/* <Link to={"#"} className="btn w100 btn_man">
                            Closed
                          </Link> */}
                          <div className="top_btn">
                            {/* {action == "Buy" ? ( */}
                            <button
                              onClick={() => handleShow("Buy")}
                              type="button"
                              className="btn w100 btn_man"
                            >
                              Deposit now
                            </button>
                            {/* ) : (
                                      ""
                                    )} */}
                          </div>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={() => handleShow("Withdrow")}
                            type="button"
                            className="btn w100 btn_man"
                          >
                            Withdrow
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </section>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton className="text-center">
          <Modal.Title>Farming</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={inputamount}
              placeholder="Enter Amount"
              onChange={handleChange5}
            />

            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={() =>
                action === "Buy" ? maxAmt(tokenbalancelp) : maxAmt(stakeamount1)
              }
              onKeyPress={validNumber}
            >
              Max
            </Button>
          </InputGroup>
          {amountErr && <span style={{ color: "red" }}>{amountErr}</span>}

          <div className="text-center input-box">
            <Button
              variant="primary"
              className={`btn btn_man btn_hh pr-5 pl-5 ${
                spinner ? "btn_loader11" : ""
              }`}
              onClick={
                action === "Buy"
                  ? !show
                    ? () => approve5()
                    : () => farming()
                  : () => farmingWithdraw()
              }
              disabled={buttonDisable || spinner}
            >
              {action === "Buy"
                ? !spinner
                  ? !show
                    ? "Approve"
                    : "Buy"
                  : null
                : "Withdraw"}
              {spinner && <span className="btn_loader"></span>}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default StakingFarming;
