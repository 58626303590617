import * as opsService from "./Ops";

import {
  applyIDO,
  getIdoData,
  getProjectDetails,
  idoBuys,
  checkMultipleIdoRequests,
  getProjectData,
  getIdoAllocations,
  idoAllocationdataByIds,
  progressDatas,
  getprogressDatas
} from "../Constant/Api";

const IdoApply = async (data, token) => {
  let result = await opsService.postdata(applyIDO, data, token);

  return result;
};
const getIdoDatas = async (data, token) => {
  let result = await opsService.postdata(getIdoData, data, token);

  return result;
};
const getProjectDetail = async (data, token) => {
  let result = await opsService.postdata(getProjectDetails, data, token);

  return result;
};
const idoBuy = async (data, token) => {
  let result = await opsService.postdata(idoBuys, data, token);

  return result;
};
const checkMultipleIdoRequest = async (data, token) => {
  let result = await opsService.postdata(checkMultipleIdoRequests, data, token);

  return result;
};
const getProjects = async (data, token) => {
  let result = await opsService.getData(getProjectData, data, token);

  return result;
};
const getIdoAllocation = async (data, token) => {
  let result = await opsService.getData(getIdoAllocations, data, token);

  return result;
};
const idoAllocationdataById = async (data, token) => {
  let result = await opsService.getData(idoAllocationdataByIds, data, token);

  return result;
};

const progressData = async (data, token) => {
  let result = await opsService.postdata(progressDatas, data, token);

  return result;
};
const getprogressData = async (data, token) => {
  let result = await opsService.postdata(getprogressDatas, data, token);

  return result;
};

export {
  IdoApply,
  getIdoDatas,
  getProjectDetail,
  idoBuy,
  checkMultipleIdoRequest,
  getProjects,
  getIdoAllocation,
  idoAllocationdataById,
  progressData,
  getprogressData
};
