import React, { useState, useEffect, useRef, useMemo } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Web3Modal from "web3modal";
import {  Contract, utils } from "ethers";
import { idoAbi, idoAddress, vestingAbi, vestingAddress } from "../constant";
import { toast } from "react-toastify";
// import { Spin } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { useTheam } from "../../contextApi/TheamProvider";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider,  formatUnits } from 'ethers'

const Claim = () => {
  const { updateTheam, theam } = useTheam();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [ButtonDisabled, setButtonDisabled] = useState(false);
const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [userVestingAmount, setUserVestingAmount] = useState([]);
  const [userVestingTime, setUserVestingTime] = useState([]);
  const [userVestingWithdrawn, setUserVestingWithdrawn] = useState([]);
  const [userPurchaseAmt, setUserPurchaseAmt] = useState("");
  const [userInPurchaseAmt, setInUserPurchaseAmt] = useState("");
  const [initialStartTime, setInitialStartTime] = useState("");
  const [initialStartTime1, setInitialStartTime1] = useState("");
  const [initialStartDate, setInitialStartDate] = useState("");
  const [checkInitial, setCheckInitial] = useState();

  const [activeTab, setActiveTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [spinner, setSpinner] = useState(true);

  const ModelRef = useRef();


  const getSignerOrProvider = async (needSigner = false) => {

 if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()



    // if (chainId != 97 ||chainId != 80001 ||chainId != 5 ) {
    //   toast.error("Change Your Network to Supported Network")
    //  }
    if (needSigner) {
          const signer = await ethersProvider.getSigner()

      return signer;
    }
    return signer;
  };

  const trueCount = userVestingWithdrawn.reduce(
    (count, item) => count + (item === true ? 1 : 0),
    0
  );

  const userRecord = async () => {
    try {
      setSpinner(true);

      const signer = await getSignerOrProvider(true);

      const address = await signer.getAddress();
      // const address = localStorage.getItem("walletAddress");
      const contract = new Contract(vestingAddress, vestingAbi, signer);
      const tx = await contract.userDetails(address);

      if (tx) {
        setTimeout(() => {
          setSpinner(false);
        }, [2000]);
        setUserVestingAmount(tx?.amounts);
        setUserVestingWithdrawn(tx?.withdrawn);
        setUserVestingTime(tx?.times);
      }

      if (tx.revertReason) {
        console.log("Revert Reason: " + tx.revertReason);
      } else {
        console.log("Transaction reverted without a reason.");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const userBuy = async () => {
    try {
      const signer = await getSignerOrProvider(true);

      const address = await signer.getAddress();
      const contract = new Contract(vestingAddress, vestingAbi, signer);

      const forInitialtx = await contract.findInitialclaim(address);

      if (forInitialtx) {
        setInUserPurchaseAmt(formatUnits(forInitialtx?._hex));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const userStartDateNow = async () => {
    try {
      const signer = await getSignerOrProvider(true);

      const contract = new Contract(vestingAddress, vestingAbi, signer);
      const tx = await contract.startdateFromNow();

      if (tx) {
        const decimalNumber = parseInt(tx?._hex, 16);



        const timestamp = decimalNumber * 1000;
        const currentDate = new Date();
        const currentUnixTimestamp = Math.floor(currentDate.getTime() / 1000);

        const year1 = currentDate.getFullYear();
        const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day1 = String(currentDate.getDate()).padStart(2, "0");
        const hour1 = String(currentDate.getHours()).padStart(2, "0");
        const minute1 = String(currentDate.getMinutes()).padStart(2, "0");

        const formattedDate1 = `${day1}/${month1}/${year1}`;
        const formattedTime1 = `${hour1}:${minute1}`;

        const targetDate = new Date(timestamp);
        const year = targetDate.getFullYear();
        const month = String(targetDate.getMonth() + 1).padStart(2, "0");
        const day = String(targetDate.getDate()).padStart(2, "0");
        const hour = String(targetDate.getHours()).padStart(2, "0");
        const minute = String(targetDate.getMinutes()).padStart(2, "0");

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hour}:${minute}`;

        setInitialStartTime1(formattedTime1);

        console.log(
          formattedTime1 > formattedTime,
          "formattedTime1 > formattedTime"
        );
        if (formattedTime1 < formattedTime) {
          setButtonDisable(true);
        }

        setInitialStartTime(formattedTime);
        setInitialStartDate(formattedDate);
      }

      if (tx.revertReason) {
        console.log("Revert Reason: " + tx.revertReason);
      } else {
        console.log("Transaction reverted without a reason.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const claimMonthlyRewards = async () => {
    try {
      setButtonDisable(true);
      const signer = await getSignerOrProvider(true);

      const contract = new Contract(vestingAddress, vestingAbi, signer);
      const tx = await contract.claimMonthlyReward();

      if (tx) {
        toast.success("Claimed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }

      if (tx.revertReason) {
        console.log("Revert Reason: " + tx.revertReason);
      } else {
        console.log("Transaction reverted without a reason.");
      }
    } catch (err) {
      if (err.message.includes("execution reverted: Claim not available yet")) {
        toast.dismiss();
        toast.error("Claim not available yet");
      }
      if (err.message.includes("execution reverted: vesting not started yet")) {
        toast.dismiss();
        toast.error("Vesting not started yet");
      }

      console.error(err);
    }
  };

  const initialClaimReward = async () => {
    try {
      const signer = await getSignerOrProvider(true);

      const contract = new Contract(vestingAddress, vestingAbi, signer);
      const tx = await contract.initialClaim();

      if (tx) {
        toast.success("Initial claim successfully");
        await tx.wait();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }

      if (tx.revertReason) {
        console.log("Revert Reason: " + tx.revertReason);
      } else {
        console.log("Transaction reverted without a reason.");
      }
    } catch (err) {
      if (
        err.message.includes(
          "execution reverted: ERC20: transfer amount exceeds balance"
        )
      ) {
        toast.dismiss();
        toast.error("Contract dont have enough balance to transfer");
      }
      if (err.message.includes("execution reverted: vesting not started yet")) {
        toast.dismiss();
        toast.error("Vesting not started yet");
      }

      // console.error(err);
    }
  };
  const initialRewardClaimCheck = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const address = await signer.getAddress();
      const contract = new Contract(vestingAddress, vestingAbi, signer);
      const tx = await contract.initalrewarclaimed(address);

      setCheckInitial(tx);
      if (tx == true) {
        setButtonDisabled(true);
      }

      if (tx.revertReason) {
        console.log("Revert Reason: " + tx.revertReason);
      } else {
        console.log("Transaction reverted without a reason.");
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    ModelRef.current = new Web3Modal({
      networks: "binance",
      providerOptions: {},
      disabledInjectedProvider: false,
    });

    const storedAddress = localStorage.getItem("address");
    const addrs = storedAddress.toLocaleLowerCase();
    if (addrs != "undefined") {
      userRecord();
      userBuy();
      userStartDateNow();
      initialRewardClaimCheck();
    }

    // setTimeout(() => {
    //   const address = localStorage.getItem("walletAddress");
    // }, 5000);
  }, [theam]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const filteredData = userVestingAmount
    .map((item, index) => {
      const projectName = "BrainChain";
      const amount = formatUnits(item?._hex);
      const timendDate = userVestingTime[index];
      const hasClaimed = userVestingWithdrawn[index];

      const decimalNumber = parseInt(timendDate?._hex, 16);

      const timestamp = decimalNumber * 1000;
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hour = String(date.getHours()).padStart(2, "0");
      const minute = String(date.getMinutes()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = `${hour}:${minute}`;

      return { projectName, amount, hasClaimed, formattedDate, formattedTime };
    })
    .filter((item) => {
      return (
        (item.projectName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.amount.includes(searchQuery.toString())) &&
        (activeTab === "All" ||
          (activeTab === "Active" && !item.hasClaimed) ||
          (activeTab === "Claimed" && item.hasClaimed))
      );
    })
    .map((item, index) => {
      return (
        <tr key={index}>
          {spinner ? (
            <div id="loader">
              <div className="loader1">
                {" "}
                <img src="../img/logo.webp" alt="header-Logo" />
              </div>
            </div>
          ) : (
            <>
              <td>
                <img
                  src="img/logo-coin.png"
                  alt="logo"
                  className="table_icon"
                />
                {item.projectName}
              </td>
              <td>10:00 UTC</td>
              <td>{item?.formattedDate}</td>
              <td>{item?.formattedTime}</td>
              <td>{item.amount}</td>
              <td>
                {item.hasClaimed ? (
                  <button className="btn btn_man" disabled>
                    Claimed
                  </button>
                ) : (
                  <button
                    onClick={claimMonthlyRewards}
                    className="btn btn_man"
                    disabled={buttonDisable}
                  >
                    Claim
                  </button>
                )}
              </td>
            </>
          )}
        </tr>
      );
    });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <Header />
      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-md-3 mb-0">Claim Panel</h2>
            </div>
          </div>

          <div className="row apc">
            <div className="col-sm-4 mb-sm-5 mb-4 ">
              <div className="launchpad_box">
                <h6 className="box_h">All</h6>
                <h3 className=" text-center mt-3 mb-0">
                  {userVestingAmount?.length}
                </h3>
              </div>
            </div>
            <div className="col-sm-4 mb-sm-5 mb-4">
              <div className="launchpad_box">
                <h6 className="box_h">Pending </h6>
                <h3 className=" text-center mt-3 mb-0">
                  {userVestingAmount?.length - trueCount}
                </h3>
              </div>
            </div>
            <div className="col-sm-4 mb-sm-5 mb-4">
              <div className="launchpad_box">
                <h6 className="box_h">Claimed</h6>
                <h3 className=" text-center mt-3 mb-0">{trueCount}</h3>
              </div>
            </div>
          </div>

          <div className="text-center">
            <ul className="nav nav_tab2  text-center mb-md-5 mb-3 monthly_linear">
              <li className="nav-item">
                <a className=" active " data-toggle="tab" href="#Monthly_tab">
                  Monthly{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="" data-toggle="tab" href="#Linear_tab">
                  Linear{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active " id="Monthly_tab">
              <div className=" row align-items-lg-center">
                <div className="col-md-6 mb-4 mb-md-0">
                  <ul className="nav nav-tabs ">
                    <li className="nav-item">
                      <a
                        href="#All_tab"
                        className={`nav-link ${
                          activeTab === "All" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("All")}
                        data-toggle="tab"
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#Active_tab"
                        className={`nav-link ${
                          activeTab === "Active" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("Active")}
                      >
                        Active
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#Claimed_tab"
                        className={`nav-link ${
                          activeTab === "Claimed" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("Claimed")}
                      >
                        Claimed
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-auto ml-auto  mb-4 mb-md-0">
                  <div className="table_serch_box">
                    <input
                      type="text"
                      placeholder="Search for Project Name"
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <i className="fa fa-search"></i>
                  </div>
                </div>
                <div className="col-auto  mb-4 mb-md-0">
                  <div className="three_icon">
                    <img src="img/bnb.png" alt="bnb" />
                    <img src="img/polygon_icon.png" alt="polygon" />
                    <img src="img/ethereum_icon.png" alt="ethereum" />
                  </div>
                </div>
              </div>
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="All_tab">
                  <div className="table-responsive table_bg">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Vesting No.</th>
                          <th>Start Date</th>
                          <th>Start Time</th>
                          <th>My Allocation</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {userInPurchaseAmt ? (
                            <tr>
                              <td>
                                <img
                                  src="img/logo-coin.png"
                                  alt="logo"
                                  className="table_icon"
                                />
                                Brainchain
                              </td>
                              <td>10:00 UTC</td>
                              <td>{initialStartDate}</td>
                              <td>{initialStartTime}</td>
                              <td>{userInPurchaseAmt}</td>
                              <td>
                                <button
                                  onClick={initialClaimReward}
                                  className="btn btn_man"
                                  disabled={ButtonDisabled}
                                >
                                  Initial Claim
                                </button>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No data found
                              </td>
                            </tr>
                          )}

                          {filteredData.length > 0 ? (
                            filteredData
                          ) : (
                            <tr>
                              {/* <td colSpan="6" className="text-center">
                                No data found
                              </td> */}
                            </tr>
                          )}
                        </>
                        {/* )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="tab-pane  " id="Linear_tab">
              <div className=" row align-items-lg-center">
                <div className="col-md-6">
                  <ul className="nav nav-tabs ">
                    <li className="nav-item">
                      <a
                        href="#All_tab"
                        className={`nav-link ${
                          activeTab === "All" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("All")}
                        data-toggle="tab"
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#Active_tab"
                        className={`nav-link ${
                          activeTab === "Active" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("Active")}
                      >
                        Active
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#Claimed_tab"
                        className={`nav-link ${
                          activeTab === "Claimed" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("Claimed")}
                      >
                        Claimed
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-auto ml-auto">
                  <div className="table_serch_box">
                    <input
                      type="text"
                      placeholder="Search for Project Name"
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <i className="fa fa-search"></i>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="three_icon">
                    <img src="img/bnb.png" alt="bnb" />
                    <img src="img/polygon_icon.png" alt="polygon" />
                    <img src="img/ethereum_icon.png" alt="ethereum" />
                  </div>
                </div>
              </div>
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="All_tab">
                  <div className="table-responsive table_bg">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Vesting No.</th>
                          <th>Start Date</th>
                          <th>Start Time</th>
                          <th>My Allocation</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkInitial ? (
                          filteredData.length > 0 ? (
                            filteredData
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No data found
                              </td>
                            </tr>
                          )
                        ) : (
                          <>
                            {" "}
                            {userPurchaseAmt ? (
                              <tr>
                                <td>
                                  <img
                                    src="img/logo-coin.png"
                                    alt="logo"
                                    className="table_icon"
                                  />
                                  Brainchain
                                </td>
                                <td>10:00 UTC</td>
                                <td>{initialStartDate}</td>
                                <td>{initialStartTime}</td>
                                <td>{(userPurchaseAmt * 10) / 100}</td>
                                <td>
                                  <button
                                    onClick={initialClaimReward}
                                    className="btn btn_man"
                                    disabled={buttonDisable}
                                  >
                                    Initial Claim
                                  </button>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  No data found
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Claim;
