import React, { useState, useEffect } from "react";
import "./customCalender.css";
import Select, { components } from "react-select";
import { getProjects } from "../services/Ido";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getProjectIdoById } from "../services/Projects";
import { Skeleton } from "antd";
const options = [
  { value: "All Rounds", label: "All Rounds" },
  { value: "Public", label: "Public" },
  { value: "Private", label: "Private" },
];

const options2 = [
  { value: "BSC", label: "Binance (BSC)", icon: "img/bnb.png" },
  { value: "ETH", label: "Ethereum (ETH)", icon: "img/ethereum_icon.png" },
  { value: "POLYGON", label: "Polygon", icon: "img/polygon_icon.png" },
  { value: "all block chain", label: "All BlockChains", icon: "img/chain.png" },
];
const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={require("./" + props.data.icon)}
      style={{ width: 36 }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#25263f" : null,
      color: "#fff",
    };
  },
};

const CustomCalendar = () => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [blockchainType, setBlockchainType] = useState(null);
  const [accessType, setAccessType] = useState("All Rounds");
  const [record, setRecord] = useState([]);

  const [filteredData, setFilteredData] = useState(record);

  const handlePrevMonth = () => {
    const prevMonthDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() - 1,
      1
    );

    setSelectedDate(
      prevMonthDate.getMonth() == new Date().getMonth() &&
        prevMonthDate.getFullYear() == new Date().getFullYear()
        ? new Date()
        : prevMonthDate
    );
  };

  const handleNextMonths = () => {
    const nextMonthDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      1
    );

    setSelectedDate(
      nextMonthDate.getMonth() == new Date().getMonth() &&
        nextMonthDate.getFullYear() == new Date().getFullYear()
        ? new Date()
        : nextMonthDate
    );
  };

  const daysInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const daysInPrevMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    0
  ).getDate();

  const startDayIndex = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  ).getDay(); // 0 for Sunday, 1 for Monday, etc.

  const prevMonthDays = Array.from({ length: startDayIndex }, (_, index) => (
    <div key={`empty-prev-${index}`} className="calendar-day empty-day">
      {daysInPrevMonth - startDayIndex + index + 1}
    </div>
  ));

  const currentMonthDays = daysArray.map((day) => (
    <div
      key={`day-${day}`}
      className={`calendar-day ${
        day === selectedDate.getDate() &&
        currentDate.getMonth() === selectedDate.getMonth() &&
        currentDate.getFullYear() === selectedDate.getFullYear()
          ? "selected"
          : ""
      }`}
    >
      {day}
    </div>
  ));

  var remainingEmptyDays;

  if (prevMonthDays.length + currentMonthDays.length > 35) {
    const totalDaysDisplayed = prevMonthDays.length + currentMonthDays.length;
    remainingEmptyDays = 42 - totalDaysDisplayed;
  } else {
    const totalDaysDisplayed = prevMonthDays.length + currentMonthDays.length;
    remainingEmptyDays = 35 - totalDaysDisplayed;
  }
  const nextMonthDays = Array.from(
    { length: remainingEmptyDays },
    (_, index) => (
      <div key={`empty-next-${index}`} className="calendar-day empty-day">
        {index + 1}
      </div>
    )
  );

  const getData = async () => {
    const config = localStorage.getItem("jwtToken");

    const res = await getProjects();

    if (res.status) {
      setRecord(res.data);
      setFilteredData(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let oldDate = new Date();
  oldDate.setHours(oldDate.getHours() + 5);
  oldDate.setMinutes(oldDate.getMinutes() + 30);
  oldDate = oldDate / 1000;

  // ======== changes ==================//

  const firstDayOfMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  );
  const startingDayOfWeek = firstDayOfMonth.getDay();

  // for (let i = 1; i <= daysInMonth; i++) {
  //   daysArray.push(i);
  // }
  // const renderEmptyDays = () => {
  //   const emptyDays = (startingDayOfWeek + 7) % 7; // Calculate the number of empty days at the beginning
  //   const emptyDayElements = [];

  //   for (let i = 0; i < emptyDays; i++) {
  //     emptyDayElements.push(
  //       <div key={`empty-day-${i}`} className="empty-day"></div>
  //     );
  //   }

  //   return emptyDayElements;
  // };
  const renderEmptyDays = (count) => {
    const emptyDayElements = [];

    for (let i = 0; i < count; i++) {
      emptyDayElements.push(
        <div key={`empty-day-${i}`} className="empty-day"></div>
      );
    }

    return emptyDayElements;
  };
  // ======== changes ==================//

  return (
    <div className="">
      <div className="custom-calendar">
        <div className="calendar-header">
          <div className="calendar-header-left">
            <button onClick={handlePrevMonth}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <h2>
              {months[selectedDate.getMonth()]} {selectedDate.getFullYear()}
            </h2>
            <button onClick={handleNextMonths}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>

          <div className="header-right">
            <ul className="list-menu">
              {/* <select
                className="form-select"
                name="access_type"
                onChange={(e) => setAccessType(e.target.value)}
              >
                <option>Select Access Type</option>
                <option value="All Rounds"> All Rounds</option>
                <option value="Public"> Public </option>
                <option value="Private"> Private </option>
              </select> */}
              <li>
                <Select
                  onChange={(e) => setAccessType(e.value)}
                  styles={colourStyles}
                  options={options}
                />
              </li>
              <li>
                {" "}
                <Select
                  onChange={(e) => setBlockchainType(e.value)}
                  styles={colourStyles}
                  options={options2}
                  components={{ Option: IconOption }}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="calander_main">
          <div className="calendar-grid week">
            {daysOfWeek.map((day) => (
              <div key={day} className="calendar-week ">
                <h6>{day}</h6>
              </div>
            ))}
          </div>

          <div className="calendar-grid">
            {startingDayOfWeek > 0 && renderEmptyDays(startingDayOfWeek)}{" "}
            {daysArray.map((day) => {
              const date = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                day
              );

              let oldDate = new Date(date);
              oldDate.setHours(oldDate.getHours() + 5);
              oldDate.setMinutes(oldDate.getMinutes() + 30);
              oldDate = oldDate / 1000;

              const matchingProjectName = filteredData.filter((match) => {
                const comparisonResult = match.ido_date === oldDate;

                return comparisonResult;
              });

              return (
                <div
                  key={`day-${day}`}
                  className={`calendar-day ${
                    day === selectedDate.getDate() &&
                    currentDate.getMonth() === selectedDate.getMonth() &&
                    currentDate.getFullYear() === selectedDate.getFullYear()
                      ? "selected"
                      : ""
                  }`}
                >
                  <span>{day}</span>

                  {matchingProjectName.length > 0 && (
                    <>
                      {matchingProjectName
                        .filter((item) => {
                          return (
                            (!blockchainType ||
                              blockchainType === "all block chain" ||
                              item.blockchain_type_ido_calander ===
                                blockchainType) &&
                            (accessType === "All Rounds" ||
                              item.access_type_ido_calander === accessType)
                          );
                        })
                        .map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            className="btn_man web_btn"
                            onClick={() =>
                              window.open(item.hyper_link, "_blank")
                            }
                          >
                            {item.project_name}
                          </button>
                        ))}
                    </>
                  )}
                </div>
              );
            })}
            {daysArray.length + startingDayOfWeek < 35 &&
              renderEmptyDays(35 - (daysArray.length + startingDayOfWeek))}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
