import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
const Footer = (props) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  //   useEffect(() => {

  //     setDefault()
  //   }, [props]);

  //  const setDefault =  ()=>{
  //   window.addEventListener("beforeinstallprompt", (event) => {

  //     setDeferredPrompt(event);
  //   });
  //  }

  //   useEffect(() => {}, [props]);

  //   const handleAddToHomeScreens = () => {

  //     if (deferredPrompt) {

  //       deferredPrompt.prompt();
  //       deferredPrompt.userChoice.then((choiceResult) => {
  //         if (choiceResult.outcome === "accepted") {

  //           console.log("User accepted the A2HS prompt");
  //         } else {
  //           console.log("User dismissed the A2HS prompt");
  //         }
  //         setDeferredPrompt(null);
  //       });
  //     } else {
  //     }
  //   };
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      console.log("hellooooo");
      // Prevent the default behavior to show the install prompt
      event.preventDefault();
      // Store the event for later use
      setDeferredPrompt(event);
    };

    // Event listener for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      // Clean up the event listener
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);
  useEffect(() => {
    const box = document.getElementById("box1");
    box.addEventListener("click", () => {
      if (box.classList.contains("toggle")) {
        box.classList.remove("toggle");
      } else {
        box.classList.add("toggle");
      }
    });
    $(document).ready(function () {
      // Handle checkbox click
      $(".nav-link1").click(function () {
        $(".body").toggleClass("mobile-menu");
      });

      // Handle nav-link click
      $("#box1").click(function () {
        $(".body").toggleClass("mobile-menu");
      });
    });
  }, []);

  const handleAddToHomeScreen = () => {
    console.log("hellooooo1");

    if (deferredPrompt) {
      console.log(deferredPrompt, "deferredPrompt1");
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        // Reset the deferredPrompt variable
        setDeferredPrompt(null);
      });
    }
  };
  return (
    <section
      className="contact padding footer"
      id="contact"
      data-scroll-index="6"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6  pr-md-5">
            <div className="navbar-brand mb-3" href="/">
              <div id="box1" className="box2">
                <span></span>
              </div>
              <img
                src="/img/logo.webp"
                alt="Brain Chain Logo"
                className="logo"
              />
              <span>BrainChain</span>
            </div>
            <p className="mb-4">
              Discover the future of crypto projects with our cutting-edge
              BrainChain Launchpad. Get access to exclusive token sales and be a
              part of the revolution.
            </p>
            <a
              href="
https://docs.brainchain.tech/brainchain"
              className="btn btn_man"
              target="_blank"
            >
              Whitepaper
            </a>
          </div>
          <div className="col-md-5 pl-md-5 left_border">
            <div className="nav_link mt-3">
              <h4> Follow Us On</h4>
              <div
                className="vertical-social wow fadeInDown  animated "
                data-wow-delay="0.1s"
              >
                <ul>
                  <li>
                    <a target="_blank" href="#">
                      {" "}
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="fa fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      {" "}
                      <img src="/img/discord.svg" alt="discord" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="fa fa-medium"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="nav_box ">
                <h4 className="mb-3 ">Want to install Brainchain App</h4>
                <button
                  onClick={handleAddToHomeScreen}
                  id="installButton"
                  className="btn btn_man add_to_home"
                >
                  Add to Home Screen
                </button>
                {/* <div className="never_news mt-4">
                        <input placeholder="Enter your mail address"/>
                        <button className="btn btn_man"><i className="fa fa-paper-plane" ></i></button>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-sm-2 text-sm-right">
              <a
                target="_blank"
                href="https://docs.brainchain.tech/brainchain/legal/privacy-policy"
              >
                Privacy Policy{" "}
              </a>
              <a
                className="ml-2 ml-md-4"
                target="_blank"
                href="
https://docs.brainchain.tech/brainchain/legal/terms-of-service"
              >
                {" "}
                Terms of Services{" "}
              </a>
            </div>
            <div className="col-md-6 order-sm-1">
              &copy; 2024 BrainChain. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
