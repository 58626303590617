import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

const Features = () => {
  return (
    <div>
      <Header />
      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-0 mb-md-2">Features</h2>
            </div>
          </div>

         
              <div className="launchpad_box mb-3">

     <table class="table table-bordered  features_table text-center">
    <thead>
      <tr>
        <th >Comparison</th>
        <th>BrainChain</th>
        <th>Other Launchpads</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td >Guranteed Allocation for all Tiers</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  <tr>
        <td >After Hack Fund Recovery for users *</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  <tr>
        <td >Incubation Benefits for users</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  {/* <tr>
        <td >Farming Tier Degradation Protection</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr> */}
	  <tr>
        <td >KYC Data Protection (No need to Upload your<br/> KYC Details on any 3rd Party Website)</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  <tr>
        <td >Complete Decentralised Launchpad</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  <tr>
        <td >Advanced Algorithms</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>
	  <tr>
        <td >Easy to Navigate UI</td>
        <td><i class="fa fa-check"></i></td>
        <td><i class="fa fa-times"></i></td>
      </tr>

    </tbody>
  </table>
     
          </div>
		  
		  <p>For more information please read our <a className="link_color" target="_blank" href="https://docs.brainchain.tech/">whitepaper</a></p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Features;
