import { baseUrl } from "./BaseUrl";

export const applyIDO = baseUrl + "/ido-apply";

export const getIdoData = baseUrl + "/ido-data";
export const getProjectDetails = baseUrl + "/project-detail-by-id";
export const stackingAdd = baseUrl + "/stacking-apply";
export const contactAdd = baseUrl + "/contact-add";
export const checkMultiple = baseUrl + "/check-multiple";
export const idoBuys = baseUrl + "/ido-buy";
export const stackingAddressData = baseUrl + "/stacking-wallet-address-data";
export const checkMultipleIdoRequests = baseUrl + "/check-multiple-ido-request";
export const getProjectData = baseUrl + "/get-ido-data";
export const getProjectIdoByIds = baseUrl + "/project-ido-by-id";
export const getIdoAllocations = baseUrl + "/get-ido-allocation-data";
export const idoAllocationdataByIds = baseUrl + "/ido-allocation-data-by-id";
export const progressDatas = baseUrl + "/progress-data";
export const getprogressDatas = baseUrl + "/get-progress-data";








