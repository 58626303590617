import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";

const StakingPoolDetailsModal = ({ stackingData, showModal, handleClose }) => {
  const [first, setfirst] = useState([]);

  useEffect(() => {
    setfirst(stackingData);
  }, [stackingData]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Staking Pool Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Table className="text-center">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Days</th>
              <th>APR</th>
              <th>Days Left</th>
            </tr>
          </thead>
          <tbody>
            {first.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.days[index + 1]}</td>
                <td>
                  {index === 0 ? "1%" : ""}
                  {index === 1 ? "3%" : ""}
                  {index === 2 ? "6%" : ""}
                  {index === 3 ? "10%" : ""}
                  {index === 4 ? "20%" : ""}
                  {index === 5 ? "50%" : ""}
                </td>
                <td>{item.daysLeft <= 0 ? 0 : item.daysLeft}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="btn_man">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StakingPoolDetailsModal;
