import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IdoApply } from "../../services/Ido";

const ApplyIdo = () => {
  const initialValues = {
    project_name: "",
    email: "",
    telegram_handle: "",
    project_brief: "",
    blockchain: "",
    project_status: "",
    raised_funds: "",
    target_raise: "",
    project_website: "",
    telegram_group: "",
    project_twitter: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (value.trim() === "") {
      error = "This field is required.";
    } else if (name === "blockchain" && !value) {
      error = "Please select a blockchain/platform.";
    } else if (value.startsWith(" ")) {
      error = "Space at the beginning is not allowed.";
    } else if (name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(value)) {
        error = "Invalid email format.";
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const newErrors = {};
    const noStartingSpaceRegex = /^[^\s].*$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    for (const key in formData) {
      if (formData[key] == "") {
        newErrors[key] = "This field is required.";
        isValid = false;
        break;
      } else if (!noStartingSpaceRegex.test(formData[key])) {
        newErrors[key] = "Space not allowed at the beginning.";
        isValid = false;
        break;
      } else if (key === "email" && !emailPattern.test(formData[key])) {
        newErrors[key] = "Invalid email format.";
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    if (isValid) {
      for (const key in formData) {
        if (!noStartingSpaceRegex.test(formData[key])) {
          toast.error(`Space not allowed at the beginning of ${key}`);
          return;
        }
      }

      const {
        project_name,
        email,
        telegram_handle,
        project_brief,
        blockchain,
        project_status,
        raised_funds,
        target_raise,
        project_website,
        telegram_group,
        project_twitter,
      } = formData;
      let data = {
        project_name,
        email,
        telegram_handle,
        project_brief,
        blockchain,
        project_status,
        raised_funds,
        target_raise,
        project_website,
        telegram_group,
        project_twitter,
      };

      const res = await IdoApply(data);
      if (res.status) {
        toast.dismiss();
        toast.success(res.message);
        setFormData(initialValues);
      } else {
        toast.dismiss();
        toast.error(res.message);
      }
    }
  };
  return (
    <div>
      <Header />
      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-3">Apply For IDO</h2>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-7 m-auto apply-form ">
              <div className="launchpad_box">
                <div className="apply-form">
                  <form>
                    <div className="input__group form-group mb-23">
                      <label>Project Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="project_name"
                        onChange={handleChange}
                        value={formData.project_name}
                        id="project"
                        placeholder="Enter the name of your project"
                        required=""
                      />
                      {errors.project_name && (
                        <span style={{ color: "red" }}>
                          {errors.project_name}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        value={formData.email}
                        placeholder="Email Address"
                        required=""
                      />
                      {errors.email && (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Telegram Handle</label>
                      <input
                        className="form-control"
                        type="text"
                        name="telegram_handle"
                        onChange={handleChange}
                        value={formData.telegram_handle}
                        id="TelegramHandle"
                        placeholder="You can provide one or more than one "
                        required=""
                      />
                      {errors.telegram_handle && (
                        <span style={{ color: "red" }}>
                          {errors.telegram_handle}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Project Brief</label>
                      <textarea
                        className="form-control"
                        name="project_brief"
                        cols="30"
                        onChange={handleChange}
                        value={formData.project_brief}
                        rows="3"
                        placeholder="Tell us about the project you want to launch"
                        required=""
                      ></textarea>
                      {errors.project_brief && (
                        <span style={{ color: "red" }}>
                          {errors.project_brief}
                        </span>
                      )}
                    </div>
                    <div className="radio-button">
                      <h5 className="mt-4 mb-3">Blockchain/Platform</h5>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="binance"
                          name="blockchain"
                          onChange={handleChange}
                          value="Binance Smart Chain"
                          checked={
                            formData.blockchain === "Binance Smart Chain"
                          }
                        />
                        <label for="binance">Binance Smart Chain</label>
                      </div>

                      <div className="input-list">
                        <input
                          type="radio"
                          id="ethereum"
                          name="blockchain"
                          onChange={handleChange}
                          value="Ethereum"
                          checked={formData.blockchain === "Ethereum"}
                        />
                        <label for="ethereum">Ethereum</label>
                      </div>

                      <div className="input-list">
                        <input
                          type="radio"
                          id="polygon"
                          name="blockchain"
                          onChange={handleChange}
                          value="Polygon (Matic)"
                          checked={formData.blockchain === "Polygon (Matic)"}
                        />
                        <label for="polygon">Polygon (Matic)</label>
                      </div>
                      {errors.blockchain && (
                        <span style={{ color: "red" }}>
                          {errors.blockchain}
                        </span>
                      )}
                      <h5 className="mt-4 mb-3">Project Status</h5>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="idea"
                          name="project_status"
                          value="Just an initial idea"
                          onChange={handleChange}
                          checked={
                            formData.project_status === "Just an initial idea"
                          }
                        />
                        <label for="idea">Just an initial idea</label>
                      </div>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="paper"
                          name="project_status"
                          value="Idea with White Paper"
                          onChange={handleChange}
                          checked={
                            formData.project_status === "Idea with White Paper"
                          }
                        />
                        <label for="paper">Idea with White Paper</label>
                      </div>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="development"
                          name="project_status"
                          value="In early development"
                          onChange={handleChange}
                          checked={
                            formData.project_status === "In early development"
                          }
                        />
                        <label for="development">In early development</label>
                      </div>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="launch"
                          name="project_status"
                          value="Ready to launch"
                          onChange={handleChange}
                          checked={
                            formData.project_status === "Ready to launch"
                          }
                        />
                        <label for="launch">Ready to launch</label>
                      </div>
                      {errors.project_status && (
                        <span style={{ color: "red" }}>
                          {errors.project_status}
                        </span>
                      )}
                      <h5 className="mt-4 mb-3">
                        Have you already raised funds ?
                      </h5>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="yes"
                          name="raised_funds"
                          value="Yes"
                          onChange={handleChange}
                          checked={formData.raised_funds === "Yes"}
                        />
                        <label for="yes">Yes</label>
                      </div>
                      <div className="input-list">
                        <input
                          type="radio"
                          id="no"
                          name="raised_funds"
                          onChange={handleChange}
                          checked={formData.raised_funds === "No"}
                          value="No"
                        />
                        <label for="no">No</label>
                      </div>
                      {errors.raised_funds && (
                        <span style={{ color: "red" }}>
                          {errors.raised_funds}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mt-4 mb-23">
                      <label>Target Raise</label>
                      <input
                        className="form-control"
                        type="text"
                        name="target_raise"
                        id="target_raise"
                        onChange={handleChange}
                        value={formData.target_raise}
                        placeholder="How much are you planning on raising on the IDO?"
                        required=""
                      />
                      {errors.target_raise && (
                        <span style={{ color: "red" }}>
                          {errors.target_raise}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Project Website / Link to whitepaper</label>
                      <input
                        className="form-control"
                        type="text"
                        name="project_website"
                        id="project_website"
                        onChange={handleChange}
                        value={formData.project_website}
                        placeholder="Enter Link"
                        required=""
                      />
                      {errors.project_website && (
                        <span style={{ color: "red" }}>
                          {errors.project_website}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Telegram group</label>
                      <div className="input-overlay">
                        <input
                          className="form-control"
                          type="text"
                          name="telegram_group"
                          onChange={handleChange}
                          value={formData.telegram_group}
                          id="telegram_group"
                          placeholder="Enter telegram group link"
                        />

                        <div className="overlay">
                          <i className="fa fa-send"></i>
                        </div>
                      </div>
                      {errors.telegram_group && (
                        <span style={{ color: "red" }}>
                          {errors.telegram_group}
                        </span>
                      )}
                    </div>
                    <div className="input__group form-group mb-23">
                      <label>Project twitter</label>
                      <div className="input-overlay">
                        <input
                          className="form-control"
                          type="text"
                          name="project_twitter"
                          id="project_twitter"
                          onChange={handleChange}
                          value={formData.project_twitter}
                          placeholder="Enter project twitter account"
                        />

                        <div className="overlay">
                          <i className="fa fa-twitter"></i>
                        </div>
                      </div>
                      {errors.project_twitter && (
                        <span style={{ color: "red" }}>
                          {errors.project_twitter}
                        </span>
                      )}
                    </div>
                    <p>
                      We will review your submission as soon as possible and
                      will contact you through your provided Email address or on
                      Telegram handle to discuss more about your project.
                    </p>
                    <span
                      style={{ color: "rgb(255 255 255 / 56%)" }}
                      className="mt-2"
                    >
                      {" "}
                      If there's no information for a field, just type 'NA' in
                      it.
                    </span>
                    <div className="input__group mt-4">
                      <button
                        type="submit"
                        className="btn btn_man"
                        onClick={handleSubmit}
                      >
                        Submit My Project
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ApplyIdo;
