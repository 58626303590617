import * as opsService from "./Ops";

import {
  stackingAdd,
  checkMultiple,
  stackingAddressData,
} from "../Constant/Api";

const stackingApply = async (data, token) => {
  let result = await opsService.postdata(stackingAdd, data, token);

  return result;
};
const checkMultipleRequest = async (data, token) => {
  let result = await opsService.postdata(checkMultiple, data, token);

  return result;
};
const getStackingAddressData = async (data, token) => {
  let result = await opsService.postdata(stackingAddressData, data, token);

  return result;
};
export { stackingApply, checkMultipleRequest, getStackingAddressData };

