import React, { useEffect, useState, useRef } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import {
  stakeaddress,
  stakeabi,
  tokenaddress,
  tokenabi,
  cakeLp,
  cakeAbi,
  babtCheckAbi,
  babtCheckContract,
  launchpadFarmingAddress,
  launchpadFarmingAbi,
} from "../constant";

import StakingPoolDetailsModal from "./partials/StakingPoolDetailsModal";

import { Modal, Button, Accordion, Card } from "react-bootstrap";
import $ from "jquery";
const Profile = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const { walletProvider } = useWeb3ModalProvider();
  const toltopOpen = () => {
    $(document).ready(function () {
      // Handle checkbox click
      $(".tooltip_in0").toggleClass("open");
    });
  };
  const toltopOpen1 = () => {
    $(document).ready(function () {
      // Handle checkbox click
      $(".tooltip_in1").toggleClass("open");
    });
  };
  const toltopOpen2 = () => {
    $(document).ready(function () {
      // Handle checkbox click
      $(".tooltip_in2").toggleClass("open");
    });
  };
  const toltopOpen3 = () => {
    $(document).ready(function () {
      // Handle checkbox click
      $(".tooltip_in3").toggleClass("open");
    });
  };
  const [stakeamount, setstakeamount] = useState(0);
  const [tokenbalance, settokenbalance] = useState(0);
  const [tokenbalancelp, settokenbalancelp] = useState(0);
  const [lockDurations, setlLockDurations] = useState(0);
  const [stackingDayLeft, setStackingDayLeft] = useState(0);
  const [stackingData, setStackingData] = useState([]);
  const [days, setDays] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // const [percentage, setpercentage] = useState(0);

  const [tier, setTier] = useState(
    "Once you will put on Staking or Farming your Tier level will be displayed"
  );
  const [isBabt, setIsBabt] = useState("NO");
  const ModelRef = useRef();
  const [show, setShow] = useState(false);
  const [reloads, setReloads] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const getSignerOrProvider = async (needSigner = false) => {
    if (!isConnected) throw Error("User disconnected");

    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();

    if (needSigner) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      return signer;
    }
    return signer;
  };

  // useEffect(()=>{

  // },[])

  const tableRows = stackingData.map((item, index) =>
    item.daysLeft >= 0 ? <span key={index}>{item.daysLeft}</span> : null
  );

  const tableday = stackingData.map((item, index) =>
    item.daysLeft >= 0 ? <span key={index}>{item.days[index + 1]}</span> : null
  );

  const findFirstNonNull = () => {
    for (let i = 0; i <= 6; i++) {
      if (tableday[i] != null) {
        return tableday[i];
      }
    }
    return "0";
  };

  const firstNonNullss = findFirstNonNull();

  const actualValue = firstNonNullss?.props?.children;

  const percentage =
    actualValue == 30
      ? "1%"
      : actualValue == 60
      ? "3%"
      : actualValue == 90
      ? "6%"
      : actualValue == 120
      ? "10%"
      : actualValue == 180
      ? "20%"
      : actualValue == 365
      ? "50%"
      : " --";
  // setpercentage(percentage)

  const findFirstNonNull1 = () => {
    for (let i = 0; i <= 6; i++) {
      if (tableRows[i] != null) {
        return tableRows[i];
      }
    }
    return "0";
  };

  const getstakeamount = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = signer;

      const maxStakes = 6;
      let array = [];

      const contractstake = new Contract(stakeaddress, stakeabi, provider);
   
      for (let stakeIndex = 1; stakeIndex <= maxStakes; stakeIndex++) {
        try {
          let daysLeft = await contractstake.stakesamount(address, stakeIndex);

          const value = daysLeft.lockTime;
          const toStr = value.toString();
          const targetTimestamp = Number(toStr);

          const currentTimestamp = Math.floor(Date.now() / 1000);
          const timeDifferenceInSeconds = targetTimestamp - currentTimestamp;
          const timeDifferenceInMinutes = Math.floor(
            timeDifferenceInSeconds / 60
          );

          array.push({
            days: [0, 30, 60, 90, 120, 180, 365],
            daysLeft: timeDifferenceInMinutes,
          });

          if (currentTimestamp < targetTimestamp) {
            setStackingDayLeft(timeDifferenceInMinutes);
          } else {
            setStackingDayLeft(0);
          }
        } catch (error) {
          console.error(`Error fetching stake ${stakeIndex}:`, error);
        }
      }
      setStackingData(array);

      const contracts = new Contract(tokenaddress, tokenabi, provider);
      const tokenBalance = await contracts.balanceOf(address);
      let bal = formatUnits(tokenBalance, 18);
      const balances = parseFloat(bal);

      settokenbalance(balances.toFixed(2));

      const contract = new Contract(stakeaddress, stakeabi, provider);
      const balance = await contract.totalStakedAmount(address);
      const originalValues = formatUnits(balance, 18);
      const roundedValues = parseFloat(originalValues).toFixed(4);
      const trimmedValues = parseFloat(roundedValues).toString();
      setstakeamount(trimmedValues);
    

      const contractss = new Contract(cakeLp, cakeAbi, provider);
      const balanceCake = await contractss.balanceOf(address);
      settokenbalancelp(formatUnits(balanceCake, 18));

      const contracttfarm = new Contract(
        launchpadFarmingAddress,
        launchpadFarmingAbi,
        provider
      );
      const days = await contracttfarm.lockDuration();
      let day = parseInt(days._hex, 16);
      setlLockDurations(day / 24);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckBabt = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();

      const contract = new Contract(babtCheckContract, babtCheckAbi, provider);
      const tx = await contract.tokenIdOf(address);
      if (tx) {
        setIsBabt("YES");
      }
    } catch (error) {
      console.error(error, "jjjj");
    }
  };

  useEffect(() => {
    const checkTier = (stakeamount) => {
      const tierAmt = [600, 1500, 3500, 7500, 15000, 35000];
      let tier = 0;

      for (let i = 0; i < tierAmt.length; i++) {
        if (stakeamount >= tierAmt[i]) {
          tier = i + 1;
        } else {
          break;
        }
      }

      return tier;
    };

    const formatTier = (tier) => {
      return tier > 0 ? `Tier ${tier}` : "No tier available";
    };

    const result = checkTier(stakeamount);
    const formattedResult = formatTier(result);
    getstakeamount();
    handleCheckBabt();

    setTier(formattedResult);
  }, [isConnected, stakeamount]);

  return (
    <div>
      <Header />
      <section className="in_page profile_box">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-md-3 mb-0">User Details </h2>
            </div>
          </div>

          <div className="row  ">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="launchpad_box height100">
                <h6 className="box_h d-flex">
                  Staking Pool Details
                  <div className="tooltip_out ml-2">
                    <i
                      className="fa fa-eye"
                      id="toltop"
                      onClick={toltopOpen}
                    ></i>
                    <div className="tooltip_in tooltip_in0 ">
                      Details of Staking pools you are enrolled in. If you are
                      enrolled in 2 or more pools click on arrow to view more
                      details.
                    </div>
                  </div>
                  <button
                    onClick={handleShow}
                    className="ml-auto btn_man"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-angle-double-right"></i>
                  </button>
                  {/* <a
                    className="ml-auto btn_man"
                    href=""
                    data-toggle="modal"
                    data-target="#spd"
                  >
                    <i className="fa fa-angle-double-right"></i>{" "}
                  </a> */}
                </h6>

                <div className="row">
                  <div className="col-4">
                    <div className="po_box">
                      Days
                      <span className="po_box_value">{findFirstNonNull()}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="po_box  ">
                 
                      APR
                      <span className="po_box_value">{percentage}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="po_box">
                      Days Left
                      <span className="po_box_value">
                        {" "}
                        {findFirstNonNull1()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 mb-4">
              <div className="launchpad_box height100">
                <h6 className="box_h">Tier level </h6>
                <div className="po_box  d-flex text-center">
                  {tier.includes("Once you will put on") ? (
                    <small className=" m-auto"> {tier}</small>
                  ) : (
                    <h4 className=" m-auto"> {tier}</h4>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 mb-4">
              <div className="launchpad_box height100">
                <h6 className="box_h">Holding BABT </h6>
                <div className="po_box d-flex">
                  <h4 className="m-auto"> {isBabt}</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="launchpad_box height100">
                <h6 className="box_h d-flex">
                  Farming Pool Details
                  <div className="tooltip_out ml-2">
                    <i className="fa fa-eye" onClick={toltopOpen1}></i>
                    <div className="tooltip_in tooltip_in1">
                      Details of Farming pool you are enrolled in.
                    </div>
                  </div>
                  <a
                    className="ml-auto btn_man"
                    href=""
                    data-toggle="modal"
                    data-target="#fpd"
                  >
                    <i className="fa fa-angle-double-right"></i>{" "}
                  </a>
                </h6>
                <div className="row">
                  <div className="col-6">
                    <div className="po_box  ">
                      APR
                      <span className="po_box_value">
                        {" "}
                        {lockDurations ? "1%" : "--"}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="po_box  ">
                      Days Left
                      <span className="po_box_value">
                        {lockDurations ? lockDurations.toFixed(2) : "--"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 order-md-1">
              <div className="launchpad_box ">
                <h6 className="box_h d-flex">
                  Staking Balance
                  <div className="tooltip_out ml-auto">
                    <i className="fa fa-eye" onClick={toltopOpen2}></i>
                    <div className="tooltip_in tooltip_in2">
                      The total amount of BRAIN tokens you have put into
                      Staking.
                    </div>
                  </div>
                </h6>
                <div className="po_box">
                  <h4 className="mt-4 mb-3 text-center">
                    {" "}
                    {stakeamount} BRAIN
                  </h4>
                </div>
              </div>

              <div className="launchpad_box mt-4">
                <h6 className="box_h d-flex">Eligible to Buy </h6>
                <div className="po_box  text-center">
                  <h4 className="mt-4 mb-3 text-center d-inline-flex">
                    {stakeamount < 600 ? (
                      <>
                        <i className="fa fa-close mr-2 text-danger"></i>
                        <h4>Private</h4>
                        <span className="border_fb"></span>
                        <i className="fa fa-close mr-2 text-danger"></i>
                        <h4>Public</h4>
                      </>
                    ) : stakeamount >= 600 && stakeamount < 15000 ? (
                      <>
                        <i className="fa fa-close mr-2 text-danger"></i>
                        <h4>Private</h4>
                        <span className="border_fb"></span>
                        <i className="fa fa-check mr-2 text-success"></i>
                        <h4>Public</h4>
                      </>
                    ) : (
                      <>
                        <i className="fa fa-check mr-2 text-success"></i>
                        <h4>Private</h4>
                        <span className="border_fb"></span>
                        <i className="fa fa-check mr-2 text-success"></i>
                        <h4>Public</h4>
                      </>
                    )}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4 order-md-3">
              <div className="launchpad_box">
                <h6 className="box_h d-flex">
                  Farming Balance
                  <div className="tooltip_out ml-auto">
                    <i className="fa fa-eye" onClick={toltopOpen3}></i>
                    <div className="tooltip_in tooltip_in3">
                      The total amount of Liquidity you have put into Farming.
                    </div>
                  </div>
                </h6>
                <div className="po_box">
                  <h4 className="mt-4 mb-3 text-center">
                    {tokenbalancelp} Cake-LP
                  </h4>
                </div>
              </div>

              <div className="launchpad_box mt-4">
                <h6 className="box_h d-flex">Wallet Balance </h6>
                <div className="po_box">
                  <h4 className="mt-4 mb-3 text-center">
                    {" "}
                    {tokenbalance} BRAIN
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 order-md-2">
              <h1 className="thank_you">
                Thanks For Being a Part of BrainChain{" "}
              </h1>
            </div>
          </div>

          {/* <div className="modal fade" id="spd">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Staking Pool Details </h4>
                  <button type="button" className="close" data-dismiss="modal">
                   
                  </button>
                </div>

                <div className="modal-body p-0">
                  <table className="table  text-center">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Days</th>
                        <th>APR</th>
                        <th>Days Left</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stackingData.map((item, index) => {
                        
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.days[index + 1]}</td>
                            {index == 0 ? <td>1%</td> : ""}
                            {index == 1 ? <td>3%</td> : ""}
                            {index == 2 ? <td>6%</td> : ""}
                            {index == 3 ? <td>10%</td> : ""}
                            {index == 4 ? <td>20%</td> : ""}
                            {index == 5 ? <td>50%</td> : ""}

                            <td>{item.daysLeft <= 0 ? 0 : item.daysLeft}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
          <div>
            {/* Button to trigger the modal */}

            {/* Modal Component */}
            <StakingPoolDetailsModal
              stackingData={stackingData}
              showModal={showModal}
              handleClose={handleClose}
            />
          </div>

          <div className="modal fade" id="fpd">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Farming Pool Details </h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body p-0">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>APR</th>
                        <th>Days Left</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                      {/* <tr>
                        <td>2</td>
                        <td>15%</td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>15%</td>
                        <td>10</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Profile;
