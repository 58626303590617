import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contactUsAdd } from "../../services/user";

const ContactUs = () => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let error = "";

    if (value.trim() === "") {
      error = "This field is required.";
    } else if (value.startsWith(" ")) {
      error = "Space at the beginning is not allowed.";
    } else if (name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(value)) {
        error = "Invalid email format.";
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };
  const addHome = async () => {};
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const newErrors = {};
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const noStartingSpaceRegex = /^[^\s].*$/;
    // for (const key in formData) {
    //   if (formData[key] == "") {
    //     newErrors[key] = "This field is required.";
    //     isValid = false;
    //     break;
    //   } else if (key === "email" && !emailPattern.test(formData[key])) {
    //     newErrors[key] = "Invalid email format.";
    //     isValid = false;
    //     break;
    //   }
    // }
    for (const key in formData) {
      if (formData[key] == "") {
        newErrors[key] = "This field is required.";
        isValid = false;
        break;
      } else if (!noStartingSpaceRegex.test(formData[key])) {
        newErrors[key] = "Space not allowed at the beginning.";
        isValid = false;
        break;
      } else if (key === "email" && !emailPattern.test(formData[key])) {
        newErrors[key] = "Invalid email format.";
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    if (isValid) {
      for (const key in formData) {
        if (!noStartingSpaceRegex.test(formData[key])) {
          toast.error(`Space not allowed at the beginning of ${key}`);
          return;
        }
      }
      const { first_name, last_name, email, message } = formData;
      let data = {
        first_name,
        last_name,
        email,
        message,
      };


      const res = await contactUsAdd(data);
      if (res.status) {
        toast.dismiss();
        toast.success(res.message);
        setFormData(initialValues);
      } else {
        toast.dismiss();
        toast.error(res.message);
      }
    }
  };
  return (
    <div>
      <Header />
      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-md-8 m-auto text-center">
              <h2 className=" hadding mb-0 mb-md-3">Get In Touch</h2>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-7 m-auto apply-form ">
              <div className="launchpad_box">
                <h4>LEAVE A MESSAGE</h4>
                <form
                  id="contact-form"
                  method="POST"
                  action="assets/php/form.php"
                >
                  <div className="row mt-4">
                    <div className="form-group  col-md-6">
                      <label for="first-name">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        placeholder="Enter First Name"
                      />
                      {errors.first_name && (
                        <span style={{ color: "red" }}>
                          {errors.first_name}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label for="last-name">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        placeholder="Enter Last Name"
                      />
                      {errors.last_name && (
                        <span style={{ color: "red" }}>{errors.last_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="email">Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter Email"
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </div>

                  <div className="form-group mb-4">
                    <label for="description">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formData.message}
                      cols="30"
                      rows="3"
                    ></textarea>
                    {errors.message && (
                      <span style={{ color: "red" }}>{errors.message}</span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn_man"
                    onClick={handleSubmit}
                  >
                    Submit Message <i className="icon-login ml-2"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer addHome={addHome} />
    </div>
  );
};

export default ContactUs;
