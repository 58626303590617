import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import CustomCalendar from "../Mycalender";
const Calendars = () => {
  return (
    <div>
      <section className="in_page">
	  <div className="Background_RectL__oAOXA"></div>
      <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <div className=" row  mb-4">
            <div className="col-12 m-auto text-center">
              <h2 className=" hadding mb-4 mb-md-5">IDOs Calendar </h2>
              <CustomCalendar />
              {/* <Calendar value={dateState} onChange={changeDate} /> */}
            </div>
          </div>

          <div id="container" className="calendar-container"></div>
        </div>
      </section>
      <Header />

      <Footer />
    </div>
  );
};

export default Calendars;
