import React, { useEffect, useState, useRef } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import $ from "jquery";
const Home = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  // useEffect(() => {
  //   const sectionId = window.location.hash.substring(1);

  //   if (sectionId) {
  //     const scrollToSection = () => {
  //       const element = document.guseLocationetElementById(sectionId);
  //       if (element) {
  //         const offset = 26;
  //         const elementPosition =
  //           element.getBoundingClientRect().top + window.pageYOffset;

  //         window.scrollTo({
  //           top: elementPosition + offset,
  //           behavior: "smooth",
  //         });
  //       }
  //     };

  //     window.addEventListener("load", scrollToSection);

  //     return () => {
  //       window.removeEventListener("load", scrollToSection);
  //     };
  //   }
  // }, []);

  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash && homeRef.current) {
      const targetRef = location.hash === "#about-us" ? aboutUsRef : homeRef;
      targetRef.current.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location.hash]);

  // const history = useLocation();

  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  const scrollToAbout = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    $(document).ready(function () {
      $("#toggler1").toggleClass("collapsed");
    });
  };
  return (
    <div>
      {/* <Header /> */}
      <Header scrollToHome={scrollToHome} scrollToAbout={scrollToAbout} />

      <header className="home" id="home" data-scroll-index="1">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
          <h1 className="mb text-center">
            UNLEASHING THE POWER OF
            <br />
            <span className="type1">
              {" "}
              <TypeAnimation
                sequence={[
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  "WEB 3.0 and AI",
                  1000,
                  "METAVERSE",
                  1000,
                  "GAMING INDUSTRY",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
              />
            </span>
          </h1>
          <div className="row align-items-center">
            <div className="col-md-3 ml-auto">
              <img
                src="img/brain.png"
                className=" img-fluid"
                alt="BrainChain Launchpad"
              />
            </div>

            <div className="col-md-7 pl-md-5">
              <h4 className=" mb-4 ">
                Building Tomorrow’s
                <br />
                Innovation Today.
              </h4>
              <div className="login_btns">
                <a href="/launchpad" className="btn btn_man mr-3">
                  Explore IDOs
                </a>
                <a href="/apply-ido" className="btn btn_man">
                  Apply for IDO
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="stap3 p80 text-center pb-0" ref={homeRef} id="about-us1">
        <div className="container-fluid">
          <div className=" row">
            <div className="col-md-9 m-auto">
              <h2 className=" hadding mb-3">
                How to Join IDOs with just 3 steps.
              </h2>
              <p>
                Choose your Tier, stake or farm $BRAIN and get access to
                exclusive token sales and be a part of the revolution.
                <br /> All tiers will receive a guaranteed allocation.
              </p>{" "}
            </div>
          </div>
          <img
            src="img/stap.png"
            className=" img-fluid roadmap1"
            alt="BrainChain How TO Join IDOs"
          />
          <img
            src="img/stap2.png"
            className=" img-fluid roadmap2"
            alt="BrainChain How TO Join IDOs"
          />
        </div>
      </div>
      <section id="about-us2">
        <div
          className="ecosystem_secrtion p80 text-center"
          id="token"
          data-scroll-index="4"
        >
          <div className="container">
            <div className=" row mb-4">
              <div className="col-md-9 m-auto text-center">
                <h2 className=" hadding mb-3">BrainChain Ecosystem</h2>
                <p>
                  Welcome to BrainChain, where we connect users to Top-Notch
                  Projects and introduce
                  <br />
                  Projects to Global Investors effortlessly.
                </p>{" "}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="img/launchpad.png" alt="Launchpad" />
                  <h4>Launchpad</h4>
                  <p>
                    Experience the future of crypto with Our Launchpad, where
                    groundbreaking projects take flight to revolutionize the
                    world of Blockchain.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="img/incubator.png" alt="Incubator" />
                  <h4>Incubator</h4>
                  <p>
                    {" "}
                    We Fuel the growth of cutting-edge crypto startups in our
                    Incubator, nurturing innovation and propelling them towards
                    boundless success.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="img/multichain_ido.png" alt=" Multichain IDOs" />
                  <h4> Multichain IDOs</h4>
                  <p>
                    {" "}
                    Amplify your crypto potential with Multichain IDOs where
                    innovation has no boundaries and we welcome diverse
                    blockchains to thrive.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img
                    src="img/nextgen_projects.png"
                    alt=" NextGen Projects "
                  />
                  <h4> NextGen Projects </h4>
                  <p>
                    {" "}
                    Step into the world of tomorrow's crypto advancements
                    through our NextGen projects, where visionary concepts lead
                    to WEB 3.0 evolution.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="img/partnerships.png" alt="Partnerships" />
                  <h4>Partnerships</h4>
                  <p>
                    {" "}
                    Beyond launching groundbreaking projects, we empower their
                    journey with unparalleled support through strategic
                    partnerships with CEXs, Media Partners, and Brand
                    Ambassadors.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="img/marketing.png" alt="Marketing" />
                  <h4>Marketing</h4>
                  <p>
                    {" "}
                    Boost your crypto project's success with our Marketing
                    Experts, where our advanced promotion strategies fuel growth
                    and yield amazing results.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="launch p80 bg_color"
        data-scroll-index="5"
        id="about-us3"
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text-center mb-5 mb-md-0">
                <img
                  className="img-fluid animation1 h450"
                  src="img/launch.png"
                  alt="launch"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="tc hadding">
                Launch
                <br />
                On BrainChain
              </h2>
              <p className="mb-4">
                Unleash your project's potential, seize the spotlight and secure
                your project's future with an exciting IDO on our cutting-edge
                crypto launchpad.
              </p>
              <a href="/apply-ido" className="btn btn_man">
                Apply for IDO
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about_us p80 "
        ref={aboutUsRef}
        data-scroll-index="5"
        id="about-us"
      >
        <div className="container ">
          <h2 className="text-center hadding">About Us</h2>
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2">
              <div className="washer mb-md-0 mb-4">
                <div className="brain"></div>
              </div>
            </div>
            <div className="col-md-6 order-md-1 taj">
              <p className="">
                BrainChain Will Be The Best Choice For Your Project.
              </p>
              <p className="">
                At our BrainChain launchpad, we have built a vibrant ecosystem
                that not only launches the projects but also helps in incubate
                them at every stage. With a passionate team of experts, we are
                dedicated to fostering the next generation of innovators and
                revolutionaries. From ideation to launches, we offer
                unparalleled support, guiding projects through every step of
                their journey, backed by state-of-the-art technology and
                strategic partnerships. By choosing our launchpad, you gain
                access to a global community of investors, influencers, and
                enthusiasts eager to witness the greatness unfold. Embrace the
                future of Web 3.0 and soar to unparalleled heights with us.
                <br />
                Your destiny awaits!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="launch p80 bg_color text-center"
        data-scroll-index="5"
        id="about-us4"
      >
        <h2 className="tc hadding mb-5">Our Partners</h2>
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-sm-4 mb-4 mb-sm-0">
              <div className="text-center">
                <img
                  className="img-fluid "
                  src="img/binance_mart_chain.png"
                  alt="Binance Smart chain"
                />
              </div>
            </div>
            <div className="col-sm-4 mb-4 mb-sm-0">
              <div className="text-center">
                <img
                  className="img-fluid "
                  src="img/polygon.png"
                  alt="Polygon"
                />
              </div>
            </div>
            <div className="col-sm-4 mb-4 mb-sm-0">
              <div className="text-center">
                <img
                  className="img-fluid "
                  src="img/ethereum.png"
                  alt="Ethereum"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq p80" data-scroll-index="5" id="about-us4">
        <h3 className="text-center hadding mb-5">
          Frequently Asked Questions (FAQs)
        </h3>
        <div className="container ">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div
                className="accordion md-accordion style-2"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div className="card">
                  <div className="card-header" role="tab" id="headingOne1">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseOne1"
                      aria-expanded="false"
                      aria-controls="collapseOne1"
                    >
                      What is BrainChain?{" "}
                    </a>
                  </div>

                  <div
                    id="collapseOne1"
                    className="collapse "
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      BrainChain is a cutting-edge crypto Launchpad and
                      Incubator designed to empower blockchain projects and
                      investors. It provides a launch platform for innovative
                      projects while offering investment opportunities to the
                      crypto community.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo2">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo2"
                      aria-expanded="false"
                      aria-controls="collapseTwo2"
                    >
                      How does BrainChain ensure security for investors?
                    </a>
                  </div>
                  <div
                    id="collapseTwo2"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      BrainChain employs robust security measures, including a
                      decentralized approach to KYC using BABT (Binance Account
                      Bound Token). We also provide after-hack protection to our
                      users, ensuring asset recovery in case a user's wallet
                      gets hacked. Please read our whitepaper for more
                      information.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo3">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo3"
                      aria-expanded="false"
                      aria-controls="collapseTwo3"
                    >
                      What is BABT, and how does it work for KYC?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo3"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo3"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      BABT (Binance Account Bound Token) is a unique solution
                      for KYC (Know Your Customer). It streamlines the
                      verification process, eliminating the need for third-party
                      KYC providers. Users simply need to hold BABT in their
                      wallet.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo4">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo4"
                      aria-expanded="false"
                      aria-controls="collapseTwo4"
                    >
                      How does the refund policy work?
                    </a>
                  </div>
                  <div
                    id="collapseTwo4"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      We offer a 7-day, No-Questions-Asked refund option. If you
                      haven't claimed the TGE and click on the Refund button, we
                      will immediately return your entire invested amount.
                      Additionally, other measures ensure protection for your
                      remaining vestings.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo5">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo5"
                      aria-expanded="false"
                      aria-controls="collapseTwo5"
                    >
                      What sets BrainChain apart from other Launchpads?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo5"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo5"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      BrainChain stands out with its dedication to
                      decentralization, robust KYC system with BABT, after-hack
                      protection system, and various unique features designed to
                      prioritize user safety and project success.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo6">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo6"
                      aria-expanded="false"
                      aria-controls="collapseTwo6"
                    >
                      How can I participate in IDOs?
                    </a>
                  </div>
                  <div
                    id="collapseTwo6"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo6"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      To participate in IDOs, stay connected with our
                      announcements, join our community, and follow our social
                      media channels for updates on upcoming projects and
                      events.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo7">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo7"
                      aria-expanded="false"
                      aria-controls="collapseTwo7"
                    >
                      How can I contact BrainChain for further inquiries?
                    </a>
                  </div>
                  <div
                    id="collapseTwo7"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo7"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      For any questions or concerns, you can reach out to us
                      through our official channels. Feel free to use our
                      contact us form on the website or join our community on
                      social media platforms.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo8">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo8"
                      aria-expanded="false"
                      aria-controls="collapseTwo8"
                    >
                      Is BrainChain open to collaborations or partnerships?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo8"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo8"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Yes, BrainChain welcomes collaborations and partnerships
                      with other blockchain projects, communities, and
                      organizations. Please contact our team to explore
                      potential collaboration opportunities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer refresh={new Date().getTime()} />
    </div>
  );
};

export default Home;
