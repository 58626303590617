// LaunchpadItem.js
import React, { useEffect, useState } from "react";
import { useTheam } from "../../contextApi/TheamProvider";
import { getprogressData } from "../../services/Ido";
const LaunchpadItem = ({key, item, todayTimestamp, baseUrls }) => {

  const { updateTheam, theam, updateAmt,totalAmts } = useTheam();
  
 
  const [progress, setProgress] = useState(0);
  const [allocationAmt, setAllocationAmt] = useState(0);

  useEffect(() => {
    // Update the progress value as needed
    if (item?.total_raise > 0) {
      setProgress((allocationAmt * 100) / item?.total_raise);
    }
    // setProgress((prevProgress) =>
    //   prevProgress < 100 ? prevProgress + 10 : 100
    // );
  }, [allocationAmt]);

  useEffect(() => {
    getprogressAmt();
  }, []);

  const getprogressAmt = async () => {
    let data = {
      project_id: item.id,
    };

    const res = await getprogressData(data);

    if (res.status) {
      setAllocationAmt(res?.data[0].AllocationAmt);
    }
  };

  return (
    <div className="col-md-4 col-sm-6 mb-4" key={key}>
      <div className="launchpad_box ">
        {todayTimestamp > item.sale_starts_in &&
        todayTimestamp <= item.sale_ends_in ? (
          <span className="lb_scale_btn">
            <span className="scale_btn "></span>
          </span>
        ) : (
          ""
        )}

        <div className="launchpad_top media">
          <a href={`/project-details?id=${item?.id}`} className="mr-3">
            <img
              src={`${baseUrls}/static/bannerImage/${item?.project_logo}`}
              className="card-img-top"
              alt="logo"
            />
          </a>
          <div>
            <h4>{item?.project_name}</h4>
            <h6>Blockchain: {item?.blockchain_type}</h6>
          </div>
        </div>

        <div className="items">
          <div className="single-item">
            <span> Total Raise:{item?.total_raise}</span>
          </div>
          <b> Round: {item?.access_type}</b>
        </div>

        <div className="item-progress ">
          <span>Progress </span>
          <div className="progress mt-1">
           

            {todayTimestamp > item.sale_ends_in ? (
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `` }}
                      aria-valuenow=""
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {``}
                    </div>
                  ) : (
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${progress.toFixed(2)}%`}
                    </div>
                  )}
          </div>
          <div className="text-right mt-2">
            <span>
              {allocationAmt?allocationAmt:"0"} /
              {item?.total_raise !== "null" ? item?.total_raise : "0"}USDT
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between launchpad_foot mt-4">
          <a href={`/project-details?id=${item?.id}`} className="btn btn_man">
            BUY
          </a>

          <div className="social">
            {item.website !== "null" && item.website !== "Null" && (
              <a target="_blank" href={`${item.website}`}>
                <i className="fa fa-globe"></i>
              </a>
            )}

            {item.twitter !== "null" && item.twitter !== "Null" && (
              <a target="_blank" href={`${item.twitter}`}>
                {" "}
                <i className="fa fa-twitter"></i>
              </a>
            )}

            {item.telegram !== "null" && item.telegram !== "Null" && (
              <a target="_blank" href={`${item.telegram}`}>
                {" "}
                <i className="fa fa-telegram"></i>
              </a>
            )}

            {item.discord !== "null" && item.discord !== "Null" && (
              <a href={`${item.discord}`} target="_blank">
                <img src="img/discord.svg" className="discord" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchpadItem;
