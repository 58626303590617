import React, { useState, useEffect, useRef, useMemo } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProjectDetail } from "../../services/Ido";
import { baseUrls } from "../../Constant/BaseUrl";

import { Modal, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import copy from "copy-to-clipboard";
import Web3Modal from "web3modal";
import $ from "jquery";
import {
  usdtTokenAndAddress,
  idoAbi,
  usdtTokenAbi,
  idoAddress,
  stakeaddress,
  stakeabi,
  tokenaddress,
  tokenabi,
} from "../constant";
import {
  idoBuy,
  checkMultipleIdoRequest,
  progressData,
  getprogressData,
} from "../../services/Ido";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheam } from "../../contextApi/TheamProvider";
const ProjectDetails = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const location = useLocation();
  const history = useNavigate();
  const id = new URLSearchParams(location.search).get("id");
  const { updateTheam, theam, totalAmts, updateAmt } = useTheam();

  useEffect(() => {
    if (!id) {
      history("/launchpad");
    }
  }, [id, location.pathname]);
  const [record, setRecord] = useState([]);
  const [progress, setProgress] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shows, setShows] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [userAddress, setuseraddress] = useState();
  const [show, setshow] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [allowance, setAllowance] = useState(0);
  const [WhiteListAmt, setWhiteListAmt] = useState(0);
  const [userPurchaseAmt, setUserPurchaseAmt] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [deadlineEnd, setDeadlineEnd] = useState(0);
  const [stakeamount, setstakeamount] = useState(0);
  const [lowerWallet, setlowerWallet] = useState("");
  const [totalAmt, setTotalAmt] = useState("");
  const [allocationAmt, setAllocationAmt] = useState(0);
  const [checkAllocation, setCheckAllocation] = useState(false);

  const [tier, setTier] = useState(0);

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (isConnected) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        // if (chainId != 97) {
        //   window.alert("Change Your Network to Sepolia Network");
        //   throw new Error("Change Your Network to Sepolia Network");
        // }
        if (needSigner) {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          return signer;
        }
        return signer;
      }
    } catch (err) {
      console.error(err);
    }
  };
  const checkWhiteListAmount = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(idoAddress, idoAbi, provider);

      const result = checkTier(stakeamount);
      const userTier = parseInt(result);

      const balance = await contract.whitelist(userTier, address);
      const userPurchaseee = await contract.purchasedByuser(address);
      setUserPurchaseAmt(formatUnits(userPurchaseee, 18));

      setWhiteListAmt(formatUnits(balance, 18));
    } catch (err) {
      console.error(err);
    }
  };
  const checkapproval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      setuseraddress(address);
      const contract = new Contract(
        usdtTokenAndAddress,
        usdtTokenAbi,
        provider
      );

      const balance = await contract.allowance(address, idoAddress);

      setAllowance(formatUnits(balance, 18));

      if (formatUnits(balance, 18) >= parseFloat(amount)) {
        setshow(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    allocationCheck();
    checkWhiteListAmount();
  }, [allocationAmt, id]);

  const [activeTab, setActiveTab] = useState("ProjectSummary");

  useEffect(() => {
    const checkTier = (stakeamount) => {
      const tierRanges = [600, 1500, 3500, 7500, 15000, 35000];
      let tier = 0;
      let stakedamount = parseInt(stakeamount);
      for (let i = 0; i < tierRanges.length; i++) {
        if (stakedamount >= tierRanges[i] && stakedamount < tierRanges[i + 1]) {
          tier = i + 1;
          break;
        } else if (
          stakedamount >= tierRanges[i] &&
          i == tierRanges.length - 1
        ) {
          tier = i + 1;
          break;
        }
      }

      return tier;
    };

    const formatTier = (tier) => {
      return tier > 0 ? tier : 0;
    };

    if (isConnected) {
      const result = checkTier(stakeamount);
      const formattedResult = formatTier(result);

      setTier(formattedResult);
    }
  }, [stakeamount, setTier, theam]);
  const checkTier = (stakeamount) => {
    const tierRanges = [600, 1500, 3500, 7500, 15000, 35000];
    let tier = 0;
    let stakedamount = parseInt(stakeamount);
    for (let i = 0; i < tierRanges.length; i++) {
      if (stakedamount >= tierRanges[i] && stakedamount < tierRanges[i + 1]) {
        tier = i + 1;
        break;
      } else if (stakedamount >= tierRanges[i] && i == tierRanges.length - 1) {
        tier = i + 1;
        break;
      }
    }

    return tier;
  };
  useEffect(() => {
    if (isConnected) {
      getstakeamount();
      checkapproval();
      checkWhiteListAmount();
      allocationCheck();
    }
  }, [theam]);
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const closeModal = () => {
    setShowModal(false);
    setAmountErr("");
    setAmount("");
    setshow(false);
  };

  const ModelRef = useRef();
  function redirect() {
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  }
  const handleShow = () => {
    checkWhiteListAmount();
    checkapproval();
    setShowModal(true);
  };

  useEffect(() => {
    if (isConnected) {
      checkapproval();
      checkWhiteListAmount();
      checkTotalBalance();
    }
  }, [theam]);

  const depositamount = async (type, trans_type) => {
    try {
      await checkapproval();
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (isConnected) {
        setuseraddress(address);

        const regex = /^[1-9][0-9]*$/;
        const isValid = regex.test(amount);
        if (isValid) {
          let amttt = amount;
          if (typeof amount == "number") {
            amttt = amount.toString();
          }
          const contract = new Contract(idoAddress, idoAbi, signer);
          const parsedAmount = parseEther(amttt);

          const tx = await contract.buyAllocation(tier, parsedAmount);
          setSpinner(true);
          setButtonDisable(true);
          const userAddress = address;
          const transactionHash = tx.hash;
          if (transactionHash) {
            await tx.wait();

            // toast.success("Buy success");
            const data = {
              userAddress,
              amount,
              project_id: id,
            };

            const res = await progressData(data);
            console.log(res, "message");
            console.log(res.message, "message");

            if (res.status) {
              // setButtonDisable(false);
              toast.dismiss();
              toast.success(res.message);
            } else {
              toast.dismiss();
              toast.error(res.message);
            }
            setSpinner(false);
            setButtonDisable(false);
            redirect();
          }
        } else {
          setAmountErr("Enter valid amount");
        }

        // redirect();
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      if (
        err.message.includes(
          `execution reverted: "Price is not set by the admin yet"`
        )
      ) {
        toast.dismiss();
        toast.error("Price is not set by the admin yet");
      }
      if (err.message.includes(`execution reverted: "Invalid tier"`)) {
        toast.dismiss();
        toast.error("You are not eligible for this ido");
      }

      if (
        err.message.includes(`execution reverted: "Address is not whitelisted"`)
      ) {
        toast.dismiss();
        toast.error("Your address is not whitelisted");
      }
      if (
        err.message.includes(
          `execution reverted: "you don't have enough balace"`
        )
      ) {
        toast.dismiss();
        toast.error("You don't have enough balance");
      }
      if (
        err.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toast.dismiss();
        toast.error(`You cannot buy more than the whitelist amount.`);
      }
      if (
        err.message.includes(
          `execution reverted: "You have already purchased the allocated amount"`
        )
      ) {
        toast.dismiss();
        toast.error(`You cannot buy more than allocated amount `);
      }
    }
  };
  useEffect(() => {
    getprogressAmt();
  }, []);

  const getprogressAmt = async () => {
    let data = {
      project_id: id,
    };

    const res = await getprogressData(data);

    if (res.status) {
      setAllocationAmt(res?.data[0].AllocationAmt);
    }
  };

  const getstakeamount = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      setuseraddress(address);
      const contract = new Contract(stakeaddress, stakeabi, provider);
      const balance = await contract.totalStakedAmount(address);

      setstakeamount(formatUnits(balance, 18));
    } catch (err) {
      console.error(err);
    }
  };

  const maxAmt = (amt) => {
    if (amt != 0) {
      setAmountErr("");
    }

    setAmount(amt);
    if (parseFloat(allowance) >= parseFloat(amt)) {
      setshow(true);
    } else {
      setshow(false);
    }
  };

  const approval = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (isConnected) {
        const contract = new Contract(
          usdtTokenAndAddress,
          usdtTokenAbi,
          signer
        );

        let amttt = amount;
        if (typeof amount == "number") {
          amttt = amount.toString();
        }
        setButtonDisable(true);
        const tx = await contract.approve(idoAddress, parseEther(amttt));
        setSpinner(true);

        await tx.wait();
        toast.success("Approval successful");
        setshow(true);
        setSpinner(false);
        setButtonDisable(false);
      } else {
        toast.dismiss();
        toast.error("Please connect with  metamask");
      }
    } catch (err) {
      setSpinner(false);
      setButtonDisable(false);
      console.error(err);
    }
  };
  // ================for using metamsk===================//

  const handleChange = (e) => {
    checkapproval();
    const { value } = e.target;

    if (parseFloat(allowance) >= parseFloat(value)) {
      setshow(true);
    } else {
      setshow(false);
    }
    setAmount(value);
    if (value === "") {
      setAmountErr("This field is required");
    } else {
      setAmountErr("");
    }
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const now = new Date().getTime();

    if (deadline <= now) {
      // setButtonDisable(true);
      const t = deadlineEnd - now;

      if (t <= 0) {
        clearInterval(intervalRef.current);
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      } else {
        const days = Math.floor(t / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((t % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds };
      }
    } else {
      // setButtonDisable(true);
      const t = deadline - now;

      if (t <= 0) {
        clearInterval(intervalRef.current);
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      } else {
        const days = Math.floor(t / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((t % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds };
      }
    }
  };

  const intervalRef = React.useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [deadline]);

  const memoizedTimeLeft = useMemo(() => {
    return timeLeft;
  }, [timeLeft]);

  const copyToClipboard = (value) => {
    if (value) {
      copy(value);
      setShows(true);
      setTimeout(() => {
        setShows(false);
      }, 1500);
    }
  };

  const checkTotalBalance = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(
        usdtTokenAndAddress,
        usdtTokenAbi,
        provider
      );
      const balance = await contract.balanceOf(idoAddress);

      setTotalAmt(formatUnits(balance, 18));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDataById();
  }, [deadline]);
 

  const getDataById = async () => {
    let data = {
      id,
    };
    setSpinner(true);
    const res = await getProjectDetail(data);

    if (res.status) {
      setRecord(res?.data[0]);

      setDeadline(res?.data[0]?.sale_starts_in * 1000);
      setDeadlineEnd(res?.data[0]?.sale_ends_in * 1000);

      setTimeout(() => {
        setSpinner(false);
      }, [2000]);
    }
  };

  setTimeout(() => {
    if (record?.total_raise > 0) {
      setProgress((allocationAmt * 100) / record?.total_raise);
    }
  }, [2000]);

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleButtonClick = () => {
    if (!show) {
      approval();
    } else {
      depositamount("deposit", "Ido");
    }
  };

  const notWhitelist = async () => {
    if (!isConnected) {
      toast.dismiss();
      toast.error("Please Connect with MetaMask");
    }

    const signer = await getSignerOrProvider(true);
    const provider = await getSignerOrProvider();
    const address = await signer.getAddress();
    const contract = new Contract(idoAddress, idoAbi, provider);
    const result = await contract.isUserwhitelisted(address);

    if (!result) {
      toast.dismiss();
      toast.error("You do not have any allocation to purchase");
    }

    //  else {
    //   toast.dismiss();
    //   toast.error("You do not have any allocation to purchase");
    // }
  };

  const allocationCheck = async () => {
    if (isConnected) {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const address = await signer.getAddress();
      const contract = new Contract(idoAddress, idoAbi, provider);
      const result = await contract.isUserwhitelisted(address);

      setCheckAllocation(result);
      await checkWhiteListAmount();
    }
  };

  useEffect(() => {
    allocationCheck();
    getstakeamount();
  }, [checkAllocation]);



  const todayTimestamp = Math.floor(Date.now() / 1000);
  return (
    <div>
      <Header />

      <section className="in_page">
        <div className="Background_RectL__oAOXA"></div>
        <div className="Background_ElipL__xhjwR"></div>

        {spinner ? (
          <div id="loader">
            <div className="loader1">
              {" "}
              <img src="../img/logo.webp" alt="header-Logo" />
            </div>
          </div>
        ) : (
          <div className="container">
            <div className=" row  mb-4">
              <div className="col-md-8 m-auto text-center">
                <h2 className=" hadding mb-md-3 mb-0">Project Details </h2>
              </div>
            </div>

            <div className="launchpad_box launchpad_box2">
              <div className=" row">
                <div className="col-sm-6  mb-md-4 mb-3">
                  <div className="launchpad_top media     align-items-center">
                    <img
                      src={
                        baseUrls + "/static/bannerImage/" + record?.project_logo
                      }
                      className="card-img-top mr-3"
                      alt="logo"
                    />
                    <div>
                      <h4>{record?.project_name}</h4>
                      <h6>Blockchain: {record?.blockchain_type}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-auto ml-sm-auto  mb-2 col-12">
                  <div className="d-sm-flex text-sm-right">
                    <div className="">
                      <b className="">
                        {" "}
                        {deadline <= new Date().getTime()
                          ? "Sale Ends In:"
                          : "Sale Starts In:"}{" "}
                      </b>

                      <div className="timer  mt-2" role="timer">
                        <div className="t_box">
                          {/* <p>
                            {" "}
                            {deadline <= new Date().getTime()
                              ? formattedDateEnd
                              : formattedDate}{" "}
                          </p> */}
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.days
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Days</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.hours
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Hours</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.minutes
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Minutes</span>
                          </div>
                        </div>
                        <div className="t_box">
                          <div className="box">
                            <p id="day">
                              {memoizedTimeLeft.seconds
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <span className="smalltext">Seconds</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      src={require("../img/logo.jpg")}
                      className="card_img_top_right ml-sm-3 mt-4 mt-md-0"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="progress-sale d-md-flex justify-content-between mt-2  fw600 progress3">
                <div>
                  Total Raise:{" "}
                  <span className="ml-auto">
                    {record?.total_raise != "null" ? record?.total_raise : "0"}{" "}
                    USDT{" "}
                  </span>
                </div>

                <div classNmae="d-flex  align-items-center">
                  {todayTimestamp < record.sale_starts_in
                    ? "Coming Soon"
                    : todayTimestamp > record.sale_starts_in &&
                      todayTimestamp < record.sale_ends_in
                    ? "Sale Ongoing"
                    : "Sale Completed"}

                  {todayTimestamp > record.sale_starts_in &&
                  todayTimestamp <= record.sale_ends_in ? (
                    <>
                      <span className="scale_btn mr-2 ml-2 d-mb2"></span> :
                      <span className="ml-auto">
                        {allocationAmt ? ` ${allocationAmt} USDT` : "-- USDT"}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="item-progress mt-2">
                <div className="progress mb-2">
                  {todayTimestamp > record.sale_ends_in ? (
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `` }}
                      aria-valuenow=""
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {``}
                    </div>
                  ) : (
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${progress.toFixed(2)}%`}
                    </div>
                  )}
                </div>
                <span className="fw600">
                  1 USDT = {record?.token_equivalent_usdt}{" "}
                  {record?.token_symbol}{" "}
                </span>
              </div>

              <div className="d-flex mt-3 align-items-center ">
                <span className="h6 fw600 mb-0">
                  Participants:{" "}
                  {record?.participants != "null" ? record?.participants : "0"}
                </span>

                <button
                  className="btn btn_man ml-auto"
                  onClick={checkAllocation ? handleShow : notWhitelist}
                  // onClick={handleShow}
                  disabled={
                    new Date().getTime() > deadlineEnd ||
                    new Date().getTime() < deadline
                      ? true
                      : false
                  }
                >
                  BUY
                </button>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 col-md-6 mb-0 mb-3">
                <div className="project-details-info launchpad_box">
                  <h4 className="box_h">Pool Information</h4>
                  <ul>
                    <li>
                      {" "}
                      Token Distribution{" "}
                      <span>{record?.total_distribution}</span>
                    </li>
                    <li>
                      {" "}
                      Min. Allocation <span>{record?.min_allocation} USDT</span>
                    </li>
                    <li>
                      {" "}
                      Max. Allocation <span>{record?.max_allocation} USDT</span>
                    </li>
                    <li>
                      {" "}
                      Token Price <span>{record?.token_price} USDT</span>
                    </li>
                    <li>
                      {" "}
                      Access Type <span>{record?.access_type}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="project-details-info launchpad_box">
                  <h4 className="box_h">Token Information</h4>

                  <ul>
                    <li>
                      {" "}
                      Token Name <span>{record?.token_name}</span>
                    </li>
                    <li>
                      {" "}
                      Token Symbol <span>{record?.token_symbol}</span>
                    </li>
                    <li>
                      {" "}
                      Decimals <span>{record?.token_decimal}</span>
                    </li>
                    <li>
                      {" "}
                      Token Contract
                      <span>
                        {/* <i className="fa fa-clone mr-2 copy_btn"></i> */}

                        {record?.token_address}

                        {shows ? (
                          <span style={{ color: "green", marginLeft: "5px" }}>
                            Copied
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              copyToClipboard(record?.token_address)
                            }
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            <FiCopy
                              title="copy"
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        )}
                      </span>
                    </li>
                    <li>
                      Total Supply <span>{record?.total_supply}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ul className="nav nav_tab2 mt-5 text-center mb-md-5 mb-4 projectsummary_tab">
              <li className="nav-item">
                <a
                  // className=" active mr-3"
                  data-toggle="tab"
                  className={`nav-link ${
                    activeTab === "ProjectSummary" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("ProjectSummary")}
                  href="#ProjectSummary"
                >
                  Project Summary{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "VestingDetails" ? "active" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => handleTabChange("VestingDetails")}
                  href="#VestingDetails"
                >
                  Vesting Details{" "}
                </a>
              </li>
            </ul>

            <div className="tab-content">
              {activeTab === "ProjectSummary" ? (
                <div
                  className={`tab-pane ${
                    activeTab === "ProjectSummary" ? "active" : ""
                  }`}
                  id="ProjectSummary"
                >
                  <div className=" row">
                    <div className="col-md-4 col-sm-12  mb-md-4 ">
                      <h3>Project Summary</h3>
                    </div>
                    <div className="col-md-8 col-sm-12  mb-4">
                      <p>{record?.project_summary}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`tab-pane ${
                    activeTab === "VestingDetails" ? "active" : ""
                  }`}
                  id="VestingDetails"
                >
                  <div className=" row">
                    <div className="col-md-4 col-sm12  ">
                      <h3>Vesting Details</h3>
                    </div>
                    <div className="col-md-8 col-sm-12  mb-4">
                      <p>{record?.vesting_details}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton className="text-center">
          <Modal.Title>
            Eligible To Buy ( Total Allocation in $:{WhiteListAmt} )
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Modal.Title className="text-center mb-2">
            Allocation Left to Buy:$
            {WhiteListAmt > 0 ? WhiteListAmt - userPurchaseAmt : "0"}
          </Modal.Title>
          <InputGroup className="mb-3">
            <Form.Control
              type="tel"
              value={amount}
              onKeyPress={validNumber}
              placeholder="Enter Amount"
              onChange={handleChange}
            />

            <Button
              variant="outline-secondary"
              id="button-addon2"
              // onClick={() => setAmount(WhiteListAmt - userPurchaseAmt)}
              onClick={() =>
                maxAmt(WhiteListAmt > 0 ? WhiteListAmt - userPurchaseAmt : "0")
              }
              onKeyPress={validNumber}
            >
              Max
            </Button>
          </InputGroup>
          {amountErr && <span style={{ color: "red" }}>{amountErr}</span>}

          <div className="text-center input-box">
            <Button
              variant="primary"
              className="btn btn_man btn_hh pr-5 pl-5"
              onClick={handleButtonClick}
              disabled={buttonDisable || spinner}
            >
              {!spinner ? (!show ? "Approve" : "Buy") : null}
              {spinner && <span className="btn_loader"></span>}
            </Button>
            {/* <Button
              variant="primary"
              className="btn btn_man pr-5 pl-5"
              onClick={
                !show ? () => approval() : () => depositamount("deposit", "Ido")
              }
            >
              {!show ? "Approve" : "Buy"}
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default ProjectDetails;
