import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

const TierSystem = () => {
  return (
    <div>
        <Header/>
           <section className="in_page">
		   <div className="Background_RectL__oAOXA"></div>
      <div className="Background_ElipL__xhjwR"></div>
        <div className="container">
            <div className=" row  mb-4">
                <div className="col-md-8 m-auto text-center">
                    <h2 className=" hadding mb-3">Tier System </h2>
                   
                </div>
            </div>
      


            <div className="row ">
                <div className="col-lg-4 col-md-6 mb-4">
                   <div className="launchpad_box text-center tier_system_box">
                    <div className="tier-type">
                        <span>T1</span>
                    </div>
                      <h4 className="">TIER 1</h4>

                      <ul className="list-unstyled2">
                        <li>
                            <span>Staking Requirements</span>
                            <span>600 BRAIN</span>
                        </li>
                        
                        <li>
                            <span>Allocation Type</span>
                            <span>Guaranteed</span>
                        </li>
						 <li>
                             <span>Eligible To Buy</span>
                             <span>Public Round</span>
                         </li>
                        <li>
                            <span>Pool Weight</span>
                            <span>2</span>
                        </li>
                    </ul>
                   </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="launchpad_box text-center tier_system_box">
                     <div className="tier-type">
                         <span>T2</span>
                     </div>
                       <h4 className="">TIER 2</h4>
 
                       <ul className="list-unstyled2">
                         <li>
                             <span>Staking Requirements</span>
                             <span>1500 BRAIN</span>
                         </li>
                         
                         <li>
                             <span>Allocation Type</span>
                             <span>Guaranteed</span>
                         </li>
						  <li>
                             <span>Eligible To Buy</span>
                             <span>Public Round</span>
                         </li>
                         <li>
                             <span>Pool Weight</span>
                             <span>6</span>
                         </li>
                     </ul>
                    </div>
                 </div>

                 <div className="col-lg-4 col-md-6 mb-4">
                    <div className="launchpad_box text-center tier_system_box">
                     <div className="tier-type">
                         <span>T3</span>
                     </div>
                       <h4 className="">TIER 3</h4>
 
                       <ul className="list-unstyled2">
                         <li>
                             <span>Staking Requirements</span>
                             <span>3500 BRAIN</span>
                         </li>
                        
                         <li>
                             <span>Allocation Type</span>
                             <span>Guaranteed</span>
                         </li>
						  <li>
                             <span>Eligible To Buy</span>
                             <span>Public Round</span>
                         </li>
                         <li>
                             <span>Pool Weight</span>
                             <span>12</span>
                         </li>
                     </ul>
                    </div>
                 </div>

                 <div className="col-lg-4 col-md-6 mb-4">
                    <div className="launchpad_box text-center tier_system_box">
                     <div className="tier-type">
                         <span>T4</span>
                     </div>
                       <h4 className="">TIER 4</h4>

                       <ul className="list-unstyled2">
                         <li>
                             <span>Staking Requirements</span>
                             <span>7500 BRAIN</span>
                         </li>
                        
                         <li>
                             <span>Allocation Type</span>
                             <span>Guaranteed</span>
                         </li>
						  <li>
                             <span>Eligible To Buy</span>
                             <span>Public Round</span>
                         </li>
                         <li>
                             <span>Pool Weight</span>
                             <span>24</span>
                         </li>
                     </ul>
                    </div>
                 </div>

                 <div className="col-lg-4 col-md-6 mb-4">
                    <div className="launchpad_box text-center tier_system_box">
                     <div className="tier-type">
                         <span>T5</span>
                     </div>
                       <h4 className="">TIER 5</h4>
 
                       <ul className="list-unstyled2">
                         <li>
                             <span>Staking Requirements</span>
                             <span>15000 BRAIN</span>
                         </li>
                         
                         <li>
                             <span>Allocation Type</span>
                             <span>Guaranteed</span>
                         </li>
						  <li>
                             <span>Eligible To Buy</span>
                             <span>Public + Private Round</span>
                         </li>
                         <li>
                             <span>Pool Weight</span>
                             <span>52</span>
                         </li>
                     </ul>
                    </div>
                 </div>

                 <div className="col-lg-4 col-md-6 mb-4">
                    <div className="launchpad_box text-center tier_system_box">
                     <div className="tier-type">
                         <span>T6</span>
                     </div>
                       <h4 className="">TIER 6</h4>
 
                       <ul className="list-unstyled2">
                         <li>
                             <span>Staking Requirements</span>
                             <span>35000 BRAIN</span>
                         </li>
                        
                         <li>
                             <span>Allocation Type</span>
                             <span>Guaranteed</span>
                         </li>
						  <li>
                             <span>Eligible To Buy</span>
                             <span>Public + Private Round</span>
                         </li>
                         <li>
                             <span>Pool Weight</span>
                             <span>120</span>
                         </li>
                     </ul>
                    </div>
                 </div>
           
             </div>
 

        </div>
        </section>

         <Footer/>
    </div>
  )
}

export default TierSystem